import { h, Component } from 'preact';
import { AuthContextProvider } from '../util/authContext';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

// Code-splitting is automated for routes

import Public from '../routes/public/index';
import Home from '../pages/home';
import Login from '../pages/login';
import SignUp from '../pages/signUp';
import ResetPassword from '../pages/resetPassword';
import FAQ from '../pages/faq'
import About from '../pages/about'
import Form from '../pages/form'
import TermsAndConditions from '../pages/termsAndConditions'

import Studies from '../pages/studies';
import Study from '../pages/study';
import ResearcherRoute from '../routes/researcher';
import ParticipantRoute from '../routes/participant';
import SuperUserRoute from '../routes/superUser';

toast.configure({
	autoClose: 4000,
	draggable: false,
	toastClassName: 'toasts',
	className: 'toastContainer',
	hideProgressBar: true,
	closeButton: false,
})

export default class App extends Component {
	render() {
		return (
			<div id="app">
				<AuthContextProvider>
					<Router>
						<Switch>
							<Route path="/studies/:id" render={props => <Public component={Study} {...props} />} />
							<Route path="/studies" render={() => <Public component={Studies} />} />
							<Route path="/create/:isResearcher" render={() => <Public component={SignUp}/>}/>
							<Route path="/create" render={() => <Public component={SignUp} />} />
							<Route path="/login" render={() => <Public component={Login} />} />
							<Route path="/resetPassword" render={() => <Public component={ResetPassword} />} />
							<Route path="/researcher" render={props => <ResearcherRoute {...props} />} />
							<Route path="/participant" render={props => <ParticipantRoute {...props} />} />
							<Route path="/super" render={props => <SuperUserRoute {...props} />} />
							<Route path="/faq" render={()=> <Public component={FAQ}/>} />
							<Route path="/about" render={() => <Public component={About}/>} />
							<Route path="/terms" render={()=> <Public component={TermsAndConditions}/>} />
							<Route path="/persist_form" render={() => <Public component={Form} />} />
							<Route path="/" exact render={() => <Public component={Home} />} />

							<Route path="/" render={() => <Redirect to='/' />} />
						</Switch>
					</Router>
				</AuthContextProvider>
			</div>
		);
	}
}
