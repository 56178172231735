import { h, Component, createRef } from 'preact';
import * as style from './style.scss';
import StudyInterface from '../../util/studyInterface';
import { parseDate } from '../../util/date';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import Quill from 'quill';
import { toast } from 'react-toastify';
import { AuthContextConsumer, AuthContextState } from '../../util/authContext';
import { UserContact } from '../../util/userInterface';
import { API_URL_PREFIX } from '../../util/authContext';
import LoadingSpinner from '../../components/loadingSpinner';

interface Props extends Partial<RouteComponentProps> {
	localStudyObject?: Partial<{
		title: string;
		description: string;
		capacity: string | number;
		contact: number;
		start_date: string;
		end_date: string;
		image_url: string;
	}>,
}
interface State {
	study?: StudyInterface | any;
	contact: UserContact;
	studyIsLoaded: boolean;
	alreadySignedUp: boolean;
}

export class OriginalStudy extends Component<Props & Partial<AuthContextState>, State> {
	quill: Quill;
	renderedElement: HTMLDivElement;
	renderedContent: HTMLElement;
	// The reference helps locate the DOM element that Quill should render to without using additional DOM operations
	articleDescription = createRef();

	/**
	 * Initialize the component by determining if the component is being used as a preview modal, or for a posted study
	 */
	componentDidMount() {
		// This DOM element is never actually attached to the DOM - it simply exists so that Quill can convert its 
		// proprietary text editor format to formatted HTML that we utilize
		this.renderedElement = document.createElement('div');
		this.quill = new Quill(this.renderedElement);

		if (this.props.localStudyObject) {
			// The component is being used as a preview modal
			const studyObject = {
				...this.props.localStudyObject!,
				click_num: 0,
				watch_num: 0,
				participant_num: 0,
				is_open: false,
				id: 0,
				serial: 'PREVIEW',
				thumbnail: null,
				view_permission: 0,
				date_added: 'Today',
			}
			this.setState({
				study: studyObject,
				studyIsLoaded: true,
				alreadySignedUp: false,
			}, async () => await this.renderPageContent());
		} else {
			fetch(`${API_URL_PREFIX}/studies/${this.props.match.params['id']}/`)
				.then(response => {
					if (response.status === 404) {
						toast('We were not able to find you the specific study you were looking for');
						this.props.history.push('/studies');
					} else if (response.ok) {
						return response.json();
					}
				})
				.then(jsonResponse => this.setState({
					study: jsonResponse,
					studyIsLoaded: true,
				}, async () => {
					await this.renderPageContent();
					const signedUpStatus = await this.fetchSignedUpStatus(this.props.match.params['id']);
					if (signedUpStatus && signedUpStatus[0] && signedUpStatus[0]['role'] === 1) {
						this.setState({ alreadySignedUp: true });
					} else {
						this.setState({ alreadySignedUp: false });
					}
				}));
		}
	}

	fetchSignedUpStatus = async (studyId) => {
		if (this.props.userInfo && !this.props.userInfo.is_researcher && !this.props.userInfo.is_superuser) {
			return await fetch(`${API_URL_PREFIX}/relations/user_study_relations/?user=${this.props.userAuth.userId}&study=${studyId}`, {
				headers: new Headers({
					'Authorization': `Bearer ${this.props.userAuth.accessToken}`,
				})
			}).then(response => response.json());
		}
	}

	renderPageContent = async () => {
		this.renderDescription();
		await this.fetchContactInfo();
	}

	fetchContactInfo = async () => {
		await fetch(`${API_URL_PREFIX}/user_contact/${this.state.study.contact}/`)
			.then(response => {
				if (response.ok) {
					return response.json();
				}
			}).then(jsonResponse => {
				this.setState({
					contact: jsonResponse,
				});
			});
		// TODO: Catch when contact fetch fails
	}

	/**
	 * Converts the proprietary Quill text-editor content to formatted HTML elements, and then
	 * populates a pre-existing element with the rendered study description
	 */
	renderDescription() {
		this.quill.setContents(JSON.parse(this.state.study.description));
		this.renderedContent = this.renderedElement.querySelector('.ql-editor');
		this.articleDescription.current.innerHTML = this.renderedContent.innerHTML;
	}

	/**
	 * When the user signs up for a study, redirect them to a component that was originally non-rendering, however
	 * now renders a modal letting the user know that they will be receiving an email shortly
	 */
	handleSignUp = () => {
		this.props.history.push(`/participant/signup/${this.props.match.params['id']}`);
	}

	public render({ localStudyObject, match }: Props & RouteComponentProps, { study, contact, studyIsLoaded, alreadySignedUp }: State) {
		return (study && studyIsLoaded ? <div class={style.study}>
			<header class={style.headerImage}>
				<img src={(localStudyObject && localStudyObject.image_url) || study.image_url || '../../assets/images/studyDefault.jpg'} alt={study.title} />
			</header>
			<main class="wrapper">
				<aside>
					{this.props.location.state && this.props.location.state.alreadySignedUp ?
						<a class={style.signUpButton + ` ${style.disabled}`}>Already Signed Up</a>
						: alreadySignedUp === undefined ? <div class={style.btnLoadingSpinnerContainer}><LoadingSpinner color='#40a2f9' /></div>
							: alreadySignedUp ? <a class={style.signUpButton + ` ${style.disabled}`}>Already Signed Up</a>
								: !localStudyObject && match ? <Link class={style.signUpButton} to={{
									pathname: `/participant/signup/${this.props.match.params['id']}`,
									state: {
										study_id: study.id,
										researcher_email: contact ? contact.email : undefined,
										researcher_name: contact ? `${contact.first_name} ${contact.last_name}` : undefined,
										study_name: study.title,
										contact_template_id: study.contact,
										screening_survey: study.screening_survey,
										acceptance_message: study.acceptance_message,
									}
								}}>Sign up Today</Link>
									// In preview mode, the Sign Up button is for display only

									: <a class={style.signUpButton} data-in-preview={!!localStudyObject}>Sign up Today</a>}

					{!localStudyObject && match ? <Link class={style.otherStudiesButton} data-in-preview={!!localStudyObject} to={this.props.userInfo ? '/participant/studiesList': '/studies'}>View Other Studies</Link> :
						<a class={style.otherStudiesButton} data-in-preview={!!localStudyObject}>View Other Studies</a>}

					<div class={style.dateAndMetric}>
						<div class={style.dates}>
							<h5>Dates open</h5>
							<p>{parseDate(study.start_date, true)} - {parseDate(study.end_date, true)}</p>
						</div>

						<div class={style.metrics}>
							{study.participant_num >= 20 && <div class={style.metric}>
								<p class={style.num}>{study.participant_num}</p>
								<p>Enrolled</p>
							</div>}
						</div>
					</div>

					<div class={style.studyMetaData}>
						<h5>Interested in participating?</h5>
						<p>Takes less than 5 minutes to complete the process.</p>
						<h5>For more information, contact:</h5>
						{contact ?
							<div class={style.contact}>
								<p><strong>{contact.first_name} {contact.last_name}</strong></p>
								<p>{contact.email}</p>
								<p>{contact.mobile}</p>
								{contact.description && <p>{contact.description}</p>}
							</div>
							: <p>You need to be logged in to see the contact information for this study</p>}

						<p class={style.supplementary}>{study.serial} | Added on&nbsp;
								{study.date_added === 'Today' ? 'Today' : parseDate(study.date_added, true)}</p>
					</div>
				</aside>
				<article>
					<header>
						<h1>{study.title}</h1>
					</header>
					<div class={style.description} ref={this.articleDescription}>
					</div>
					<div class={style.mobileMetaData}>
						<h5>Interested in participating?</h5>
						<p>Takes less than 5 minutes to complete the process.</p>
						<h5>For more information, contact:</h5>
						{contact ?
							<div class={style.contact}>
								<p><strong>{contact.first_name} {contact.last_name}</strong></p>
								<p>{contact.email}</p>
								<p>{contact.mobile}</p>
								{contact.description && <p>{contact.description}</p>}
							</div>
							: <p>You need to be logged in to see the contact information for this study</p>}

						<p class={style.supplementary}>{study.serial} | Added on&nbsp;
								{study.date_added === 'Today' ? 'Today' : parseDate(study.date_added, true)}</p>
					</div>
				</article>
			</main>
		</div> : <div class={style.study}>
				<div class={style.loadingSpinnerContainer}>
					<LoadingSpinner color='#40a2f9' size={64} />
				</div>
			</div>)
	}
}

// Without type coercion, TypeScript will complain since withRouter from react-router-dom 
// only accepts React components, not preact, even though deep down it works just fine
export default AuthContextConsumer(withRouter<Partial<Props> & RouteComponentProps, OriginalStudy | any>
	(props => <OriginalStudy {...props} /> as any));