/**
 * Takes a phone number of the following formats and returns that same phone number 
 * in the E.164 format. If the provided phone number does not match the accepted formats,
 * an exception will be thrown.
 * 
 * @example formatPhoneNumber('5550012345') => '+15550012345'
 * @example formatPhoneNumber('(555)001-2345') => '+15550012345'
 * @example formatPhoneNumber('555-001-2345') => '+15550012345'
 * @example formatPhoneNumber('+15550012345') => '+15550012345'
 * 
 * @return {string} The formatted phone number
 */
export const formatPhoneNumber = (phone: string): string => {
    if (phone.match(/\d{10}/)) {
        return `+1${phone}`;
    } else if (phone.match(/\(\d{3}\) ?\d{3}-\d{4}/)) {
        return `+1${phone.replace(/-| |\(|\)/g, '')}`;
    } else if (phone.match(/\d{3}-\d{3}-\d{4}/)) {
        return `+1${phone.replace(/-/g, '')}`;
    } else if (phone.match(/\+1\d{10}/)) {
        return phone;
    } else {
        throw 'Invalid phone number format';
    }
}