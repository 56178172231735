import { h, Component } from 'preact';
import * as style from './style.scss';
import { AuthContextConsumer, AuthContextState } from "../../util/authContext";
import { withRouter, RouteComponentProps, Redirect, Link } from "react-router-dom";
import CustomForm from '../../components/customForm';
import { UsernameInput, PasswordInput } from '../../components/customInput';

interface State {
	serverError: string;
}

class OriginalLogin extends Component<Partial<AuthContextState> & RouteComponentProps, State> {
	state: State = {
		serverError: undefined,
	}
	
	// Account for if the username is a phone number (and make sure it's in the right format if it is)
	newAuth = async ({ username, password }: { username: string, password: string }) => {
		// Not using the "formatPhoneNumber" helper function since the username could be either a phone
		// number or an email address
		if (username.match(/\d{10}/)) {
			username = `+1${username}`;
		} else if (username.match(/\(\d{3}\) ?\d{3}-\d{4}/)) {
			username = `+1${username.replace(/-| |\(|\)/g, '')}`;
		} else if (username.match(/\d{3}-\d{3}-\d{4}/)) {
			username = `+1${username.replace(/-/g, '')}`;
		}
		
		// If the username and password are correct, will return true, else return false
		const loginResult = await this.props.getNewToken(username, password);
		if (!loginResult) {
			/*
			 * If the username/password combination was incorrect, then throw an error to the state 
			 * Not being too specific is purposeful, it makes it harder to "game" the login process and 
			 * gain information aboutuser informatoin from system messages.
			 */
			this.setState({
				serverError: 'Incorrect username or password',
			});
		} else {
			if (this.state.serverError) {
				this.setState({
					serverError: undefined,
				});
			}
			await this.props.getUserInfo();
			if (this.props.userInfo) {
				if (this.props.location.state && this.props.location.state.redirect) {
					// Passing the entire state object so that when an authenticated user logs in and signs up for
					// a study, the StudySignUp component can correctly receive the study ID and contact template ID
					this.props.history.push(this.props.location.state.redirect, this.props.location.state);
				}
			}
		}
		
	}
	
	//Overload the cancel button of the custom form to route to user creation
	createAccount = async (): Promise<void> => {
		this.props.history.push('/create');
	}

	public render(props: Partial<AuthContextState> & RouteComponentProps, { serverError }: State) {
		// If a user is already logged in, redirect to their respective "Studies" page
		if (props.userInfo) {
			if (props.userInfo.is_superuser) {
				return <Redirect to="/super/studiesList" />
			} else if (props.userInfo.is_researcher) {
				return <Redirect to="/researcher/studiesList" />
			} else {
				return <Redirect to="/participant/studiesList" />
			}
		} else {
			return <div class={style.login}>
				<div class={style.box}>
					<h1>Please Login</h1>
					<p>Please enter your Email address/phone number and password to sign in.</p>
					<CustomForm
						// The left button of a Custom defaults to the cancel action. Here it is being
						// overloaded to redirect to the account creation page
						cancelCallback={this.createAccount}
						submitCallback={this.newAuth}
						useAsyncButton={true}
						cancelText="Create Account">
						<UsernameInput name="username" errors={{serverError: serverError || ''}}/>
						
						{/* When the server returns an error, the password input box will have a red outline
						but would NOT have an error message so that there's less pressure on the user */}
						<PasswordInput name="password" errors={{serverError: serverError ? ' ' : ''}}/>
						<Link class={style.resetPassword} to="/resetPassword">Forgot Your Password?</Link> 
					</CustomForm>
				</div>
			</div>
		}
	}
}

// Without type coercion, TypeScript will complain since withRouter from react-router-dom 
// only accepts React components, not preact, even though deep down it works just fine
export default AuthContextConsumer(withRouter(OriginalLogin as any));