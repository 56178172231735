import { h, Component } from 'preact';
import * as style from './style.scss';
import StudyCard from '../../components/studyCard';
import StudyInterface from '../../util/studyInterface';
import { API_URL_PREFIX, AuthContextConsumer, AuthContextState } from '../../util/authContext';
import { toast } from 'react-toastify';
import LoadingSpinner from '../../components/loadingSpinner';
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom';

interface Props extends RouteComponentProps, AuthContextState {
	userId?: string;
}

interface State {
	apiResponseResults: StudyInterface[];
	studiesAreLoaded: boolean;
}

class OriginalStudies extends Component<Props, State> {
	state: State = {
		apiResponseResults: [],
		studiesAreLoaded: false,
	}

	/**
	 * When the page is mounted, get all the studies from the server and display them for the user
	 * When supplied with a userId prop, the component fetches only the studies created by a specific user
	 */
	componentDidMount() {
		fetch(`${API_URL_PREFIX}/studies/${this.props.userId ? `?created_by=${this.props.userId}` : ''}`)
			.then(response => {
				if (response.ok) {
					return response.json();
				} else {
					toast('Oops, we encountered some trouble getting the studies from the server');
				}
			})
			.then(jsonResponse => {
				this.setState({
					apiResponseResults: jsonResponse['results'],
					studiesAreLoaded: true,
				}, () => {
					if (jsonResponse['next']) {
						this.recursivelyFetchAdditionalStudies(jsonResponse['next'])
					}
				});
			})
			.catch(err => console.warn('Error! ', err));
	}

	/**
	 * Recursive function that determines if there is another page to the paginated server responses and continues
	 * fetching until it has retreived all pages
	 * @param {string} url The endpoint that contains subsequent paginated studies
	 */
	private recursivelyFetchAdditionalStudies(url: string): void {
		fetch(url)
			.then(response => response.json())
			.then(jsonResponse => {
				this.setState(state => ({
					apiResponseResults: state.apiResponseResults.concat(jsonResponse['results'])
				}), () => {
					if (jsonResponse['next']) {
						this.recursivelyFetchAdditionalStudies(jsonResponse['next']);
					}
				});
			})
	}

	public render({ userId, userInfo }: Props, { apiResponseResults, studiesAreLoaded }: State) {
		return userInfo ?
			userInfo.is_researcher ? <Redirect to="/researcher/studiesList" /> :
				userInfo.is_superuser ? <Redirect to="/super/studiesList" /> :
					<Redirect to="/participant/studiesList" /> : 
			(
				<div class={style.studies}>
					<h1>{userId ? 'Your Studies' : 'All Studies'}</h1>
					{studiesAreLoaded ? apiResponseResults.length === 0 && <p>No studies are currently available</p>
						: <div class={style.loadingSpinnerContainer}>
							<LoadingSpinner color='#40a2f9' size={64} />
						</div>}
					<div class={`${style.studiesContainer} ${userId ? style.constrainedStudiesContainer : ''}`}>
						{apiResponseResults.reverse().map(item => <StudyCard study={item} />)}
					</div>
					<footer>©️2020 Moshemu. All Rights Reserved</footer>
				</div>
			);
	}
}

// Without type coercion, TypeScript will complain since withRouter from react-router-dom 
// only accepts React components, not preact, even though deep down it works just fine
export default AuthContextConsumer(withRouter<Partial<Props> & RouteComponentProps, OriginalStudies | any>
	(props => <OriginalStudies {...props} /> as any));