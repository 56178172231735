import { h, Component, Fragment, createRef } from 'preact';
import * as style from './style.scss';

interface Props {
	errors?: {
		valueMissing?: string;
		tooShort?: string;
		tooLong?: string;
		patternMismatch?: string;
		rangeOverflow?: string;
		rangeUnderflow?: string;
		serverError?: string;
	};

	name: string;
	disableLabel?: boolean;
	description?: string;
	initialValue?: string | string[];
	label?: string;
	max?: string;
	min?: string;
	hide?: boolean;
	placeholder?: string;
	pattern?: string;
	required?: boolean;
	showRequiredAsterisk?: boolean;
	tooltip?: string;
	type?: string;

	elementId?: string;
	elementClass?: string;

	handleBlur?: (event: Event) => void;
	handleInput?: (event: Event) => void;
}

interface State {
	badInput?: boolean;
	customError?: boolean;
	patternMismatch?: boolean;
	rangeOverflow?: boolean;
	rangeUnderflow?: boolean;
	stepMismatch?: boolean;
	tooLong?: boolean;
	tooShort?: boolean;
	typeMismatch?: boolean;
	valid?: boolean;
	valueMissing?: boolean;
	value: any;
	selectedCheckboxValues?: any;
}

export default class CustomInput extends Component<Partial<Props>, State> {
	state: State = {
		valid: true,
		valueMissing: false,
		tooShort: false,
		tooLong: false,
		patternMismatch: false,
		value: '',
	}

	/**
	 * Triggers the blur callback with the input field, if passed as a prop, and then updates the
	 * component state to store the validity state and value of this input field
	 * 
	 * @param {Event} event The event emitted by the input field in this CustomInput component
	 */
	handleBlur = (event: Event) => {
		if (this.props.handleBlur) this.props.handleBlur(event);
		const inputField: HTMLInputElement = event.target as HTMLInputElement;
		this.setState(inputField.validity);
		this.setState({ value: inputField.value });
	}

	/**
	 * Sets the default input function for the CustomInput types (EmailInput/PasswordInput) and sets the state if
	 * the input is valid. Also constantly updates the value
	 * @param {Event} event The event emitted by the input field in this CustomInput component
	 */
	handleInput = (event: Event) => {
		if (this.props.handleInput) this.props.handleInput(event);
		const inputField: HTMLInputElement = event.target as HTMLInputElement;

		// Immediately updates the state when the input contains a valid value, so that the error outline and
		// message disappears on valid input. The error outline and message will only show on blur, but not on input
		if (inputField.validity.valid) {
			this.setState(inputField.validity);
		}
		this.setState({ value: inputField.value });
	}

	/**
	 * Pre-populate the input field with an initial value, if supplied with one from the props
	 */
	componentDidMount() {
		if (this.props.initialValue) {
			this.setState({ value: this.props.initialValue });
		}
	}

	/**
	 * Used to avoid conflicts for input fields with initial values that are updated in runtime
	 */
	componentDidUpdate(prevProps: Props) {
		if (this.props.initialValue && prevProps.initialValue !== this.props.initialValue) {
			this.setState({ value: this.props.initialValue });
		}
	}

	public render(
		{ errors, handleBlur, handleInput, ...rest }: Props,
		{ valid, valueMissing, tooShort, tooLong, patternMismatch, rangeOverflow, rangeUnderflow, value }: State
	) {
		const finalizedProps: Props = {
			errors: {
				valueMissing: 'This field is required',
				tooShort: undefined,
				tooLong: undefined,
				patternMismatch: 'Please enter a valid value',
				...errors,
			},
			name: 'customInput',
			label: 'Field',
			disableLabel: false,
			description: '',
			placeholder: 'Enter value here',
			showRequiredAsterisk: true,
			pattern: undefined,
			required: true,
			type: 'text',
			elementClass: null,
			min: undefined,
			max: undefined,
			...rest // override after default values
		}

		return (
			<fieldset class={style.customInput + (finalizedProps.hide ? ` ${style.hide}` : '')}>
				{!finalizedProps.disableLabel &&
					<label for={finalizedProps.type}>
						{finalizedProps.label}
						{finalizedProps.tooltip &&
							// The "Question mark" tooltip that users can hover over for explanations of this input field
							<div class={style.tooltip}>
								<span class={style.tooltipMark} >?</span>
								<span>{finalizedProps.tooltip}</span>
							</div>}
						{finalizedProps.required && finalizedProps.showRequiredAsterisk && <span><sup class={style.requiredAsterisk}>*</sup></span>}
					</label>}
				{finalizedProps.description && <p class={style.description}>{finalizedProps.description}</p>}
				<input type={finalizedProps.type}
					name={finalizedProps.name}
					placeholder={finalizedProps.placeholder}
					pattern={finalizedProps.pattern}
					required={finalizedProps.required}
					value={value}
					id={finalizedProps.elementId}
					class={(finalizedProps.elementClass ? finalizedProps.elementClass : '') + (valid && !finalizedProps.errors.serverError ? '' : ` ${style.inputErrBox}`)}
					onBlur={this.handleBlur}
					onInput={this.handleInput}
					min={finalizedProps.min}
					max={finalizedProps.max}
				/>
				<p class={style.inputErrMsg}>{finalizedProps.errors.serverError && <p class={style.inputErrMsg}>{finalizedProps.errors.serverError}{!valid ? `, ` : ''}</p>}
					{!valid ?
						valueMissing ? <p class={style.inputErrMsg}>{finalizedProps.errors.valueMissing}</p> :
							tooShort ? <p class={style.inputErrMsg}>{finalizedProps.errors.tooShort}</p> :
								tooLong ? <p class={style.inputErrMsg}>{finalizedProps.errors.tooLong}</p> :
									patternMismatch ? <p class={style.inputErrMsg}>{finalizedProps.errors.patternMismatch} </p> :
										rangeOverflow ? <p class={style.inputErrMsg}>{finalizedProps.errors.rangeOverflow} </p> :
											rangeUnderflow && <p class={style.inputErrMsg}>{finalizedProps.errors.rangeUnderflow} </p>
						: <p class={style.inputErrMsg}>&nbsp;</p>}</p>
			</fieldset>
		)
	}
}

export class EmailInput extends Component<Partial<Props>> {
	public render({ errors, ...rest }: Partial<Props>) {
		return (
			<CustomInput
				errors={{
					valueMissing: 'Email is required',
					patternMismatch: 'Please enter a valid email address',
					...errors,
				}}
				name='email'
				label='Email'
				placeholder='Email Address'
				pattern='[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,}$'
				type='email'
				{...rest} />
		)
	}
}

export class PasswordInput extends Component<Partial<Props>> {
	public render({ errors, ...rest }: Partial<Props>) {
		return (
			<CustomInput
				errors={{
					valueMissing: 'Password is required',
					...errors,
				}}
				name='password'
				label='Password'
				placeholder='Password'
				type='password'
				{...rest} />
		)
	}
}

export class UsernameInput extends Component<Partial<Props>> {
	public render({ errors, ...rest }: Partial<Props>) {
		return (
			<CustomInput
				errors={{
					valueMissing: 'Your email or phone number is required',
					patternMismatch: 'Please enter a valid email or phone number',
					...errors,
				}}
				name='username'
				label='Email or Phone'
				placeholder='Email or Phone'
				pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}|[\(][0-9]{3}[\)] ?[0-9]{3}-[0-9]{4}|[0-9]{10}|\+1[0-9]{10}|[0-9]{3}-[0-9]{3}-[0-9]{4}'
				type='text'
				{...rest} />
		)
	}
}

export class TelephoneInput extends Component<Partial<Props>, State> {
	state: State = {
		valid: true,
		valueMissing: false,
		tooShort: false,
		tooLong: false,
		patternMismatch: false,
		value: '',
	}

	/**
	 * Triggers the blur callback with the input field, if passed as a prop, and then updates the
	 * component state to store the validity state and value of this input field
	 * 
	 * @param {Event} event The event emitted by the input field in this CustomInput component
	 */
	handleBlur = (event: Event) => {
		if (this.props.handleBlur) this.props.handleBlur(event as any);
		let inputField: HTMLInputElement = event.target as HTMLInputElement;
		this.setState(inputField.validity);
		if (this.props.required !== false && inputField.value === '(___)___-____') {
			// It's not technically empty for a RegExp but semantically is an empty phone number
			// So we manually override and still claim that a required field is missing
			this.setState({
				valid: false,
				valueMissing: true,
			});
		}
		this.setState({ value: inputField.value });
	}

	/**
	 * Sets the default input function for the CustomInput types (EmailInput/PasswordInput) and sets the state if
	 * the input is valid. Also constantly updates the value
	 * @param {Event} event The event emitted by the input field in this CustomInput component
	 */
	handleInput = (event: Event) => {
		if (this.props.handleInput) this.props.handleInput(event as any);
		let inputField: HTMLInputElement = event.target as HTMLInputElement;
		if (inputField.validity.valid) {
			this.setState(inputField.validity);
		}
		this.setState({ value: inputField.value });
	}

	componentDidMount() {
		if (this.props.initialValue) {
			this.setState({ value: this.props.initialValue });
		}
	}

	/**
	 * Used to avoid conflicts for input fields with initial values that are updated in runtime
	 */
	componentDidUpdate(prevProps: Props) {
		if (this.props.initialValue && prevProps.initialValue !== this.props.initialValue) {
			this.setState({ value: this.props.initialValue });
		}
	}

	public render(
		{ errors, handleBlur, handleInput, required, ...rest }: Props,
		{ valid, valueMissing, tooShort, tooLong, patternMismatch, rangeOverflow, rangeUnderflow, value }: State
	) {
		const finalizedProps: Props = {
			errors: {
				valueMissing: 'Phone number is required',
				tooShort: undefined,
				tooLong: undefined,
				patternMismatch: 'Please enter a valid phone number',
				...errors,
			},
			name: 'tel',
			disableLabel: false,
			description: '',
			label: 'Cell Phone Number',
			placeholder: 'Phone Number',
			// A non-required phone number field needs a special pattern so that the input mask is not 
			// in conflict with the validity checker
			pattern: '[\(][0-9]{3}[\)][0-9]{3}-[0-9]{4}|[0-9]{10}|[0-9]{3}-[0-9]{3}-[0-9]{4}|[\+]1[0-9]{10}',
			elementClass: null,
			required: true,
			showRequiredAsterisk: true,
			min: undefined,
			max: undefined,
			...rest,
		}

		return (
			<fieldset class={style.customInput + (finalizedProps.hide ? ` ${style.hide}` : '')}>
				{!finalizedProps.disableLabel &&
					<label for={finalizedProps.type}>
						{finalizedProps.label}
						{finalizedProps.required && finalizedProps.showRequiredAsterisk && <span><sup class={style.requiredAsterisk}>*</sup></span>}
						{finalizedProps.tooltip &&
							<div class={style.tooltip}>
								<span class={style.tooltipMark} >?</span>
								<span>{finalizedProps.tooltip}</span>
							</div>}
					</label>}
				{finalizedProps.description && <p class={style.description}>{finalizedProps.description}</p>}
				<input type="tel"
					name={finalizedProps.name}
					placeholder={finalizedProps.placeholder}
					pattern={finalizedProps.pattern}
					required={finalizedProps.required}
					id={finalizedProps.elementId}
					value={value}
					onBlur={this.handleBlur}
					onChange={this.handleInput}
					min={finalizedProps.min}
					max={finalizedProps.max}
					class={(finalizedProps.elementClass ? finalizedProps.elementClass : '') + (valid && !finalizedProps.errors.serverError ? '' : ` ${style.inputErrBox}`)}
				/>

				<p class={style.inputErrMsg}>{finalizedProps.errors.serverError && <p class={style.inputErrMsg}>{finalizedProps.errors.serverError}{!valid ? `, ` : ''}</p>}
					{!valid ?
						valueMissing ? <p class={style.inputErrMsg}>{finalizedProps.errors.valueMissing}</p> :
							tooShort ? <p class={style.inputErrMsg}>{finalizedProps.errors.tooShort}</p> :
								tooLong ? <p class={style.inputErrMsg}>{finalizedProps.errors.tooLong}</p> :
									patternMismatch ? <p class={style.inputErrMsg}>{finalizedProps.errors.patternMismatch} </p> :
										rangeOverflow ? <p class={style.inputErrMsg}>{finalizedProps.errors.rangeOverflow} </p> :
											rangeUnderflow && <p class={style.inputErrMsg}>{finalizedProps.errors.rangeUnderflow} </p>
						: <p class={style.inputErrMsg}>&nbsp;</p>}</p>
			</fieldset>
		)
	}
}

interface MultiInputProps extends Partial<Props> {
	options: string[];
	hasMandatoryTextualInput?: boolean;
	mandatoryTextualInputPlaceholder?: string;
	hasOther?: boolean;
	acceptTextualOther?: boolean;
	otherPlaceHolder?: string;
	showDropdown?: boolean;
	dropdownOptions?: string[];
	dropdownDefault?: string;
	initialValueArray?: any[];
}

export class CustomMultiInput extends Component<Partial<MultiInputProps>, Partial<State>> {
	state: Partial<State> = {
		valid: true,
		valueMissing: false,
		selectedCheckboxValues: {},
	}

	fieldsetRef = createRef();
	checkboxes: HTMLInputElement[] = [];

	/**
	 * Triggers the blur callback with the input field, if passed as a prop, and then updates the
	 * component state to store the validity state and value of this input field
	 * 
	 * CustomMultiInput handles validity a little differently from other types of inputs, especially
	 * checkboxes. Specifically, a non-required set of checkboxes is always valid, and a required-set
	 * of checkboxes will be valid as long as at least one of the checkboxes is checked.
	 * 
	 * @param {Event} event The event emitted by the input field in this CustomInput component
	 */
	handleBlur = (event: Event) => {
		if (this.props.handleBlur) this.props.handleBlur(event);
		let inputField: HTMLInputElement = event.target as HTMLInputElement;
		if (this.props.type === 'radio') {
			this.setState(inputField.validity);
			if (inputField.checked && inputField.validity.valid) this.setState({ value: inputField.value });
		} else if (this.props.type === 'checkbox' && this.props.required) {
			this.checkboxes.every(item => !item.checked) ?
				this.setState({ valid: false, valueMissing: true }) :
				this.setState({ valid: true, valueMissing: false })
		}
	}

	/**
	 * Sets the default input function for the CustomInput types (EmailInput/PasswordInput) and sets the state if
	 * the input is valid. Also constantly updates the value by programmatically toggling the checkboxes. This
	 * function also toggles the display for any "mandatory supplementary input" fields, such as the frequency
	 * for substance use.
	 * 
	 * @param {Event} event The event emitted by the input field in this CustomInput component
	 */
	handleInput = (event: Event) => {
		if (this.props.handleInput) this.props.handleInput(event);
		let inputField: HTMLInputElement = event.target as HTMLInputElement;
		if (this.props.type === 'radio') {
			if (inputField.validity.valid) {
				this.setState(inputField.validity);
			}
			this.setState({ value: inputField.value });
		} else if (this.props.type === 'checkbox') {
			inputField.setAttribute('checked', String(inputField.checked));

			let selectedValues: object = {};
			this.checkboxes.forEach(item => {
				const sibling = item.nextSibling.nextSibling as HTMLInputElement;
				if (item.checked) {
					selectedValues[item.value] = '';
					if (sibling.getAttribute('data-input-type') === 'checkbox-mandatory-text') {
						selectedValues[item.value] = sibling.value;
					}
				}
			});
			this.setState({
				selectedCheckboxValues: selectedValues,
			});
			this.checkboxes.every(item => !item.checked) ?
				this.setState({ valid: false, valueMissing: true }) :
				this.setState({ valid: true, valueMissing: false });
		}
	}

	/**
	 * Initialize the checkboxes member variable on init
	 */
	componentDidMount() {
		if (this.props.type === 'checkbox') {
			this.fieldsetRef.current.querySelectorAll('input[type=checkbox]').forEach(item => {
				this.checkboxes.push(item as HTMLInputElement);
			});
		} else if (this.props.type === 'radio' && this.props.initialValue) {
			this.setState({
				value: this.props.initialValue,
			});
		}
	}

	/**
	 * Prefill the checkboxes with the initialValue array, if supplied with one, and then handles
	 * all subsequent input changes so that the initialValue does not override the values that users
	 * select in runtime
	 */
	componentDidUpdate(prevProps: MultiInputProps) {
		if ((this.props.initialValue && this.props.initialValue !== prevProps.initialValue) || 
		(this.props.initialValueArray && this.props.initialValueArray !== prevProps.initialValueArray)) {
			if (this.props.type === 'checkbox') {
				this.checkboxes.forEach((item, index) => {
					if (this.props.initialValueArray) {
						if (this.props.initialValueArray[index].value || this.props.initialValueArray[index] === true) {
						// Prefilling both the checkbox and its affiliated dropdown, if any
							let newSelectedCheckboxValues = this.state.selectedCheckboxValues;
							newSelectedCheckboxValues[item.value] = this.props.initialValueArray[index].frequency || '';
							this.setState({
								selectedCheckboxValues: newSelectedCheckboxValues,
							});
						} 
					} else if (this.props.initialValue && this.props.initialValue[index]) {
						let newSelectedCheckboxValues = this.state.selectedCheckboxValues;
						// Only need to add a key to the state since render() will utilize Object.hasOwnProperty()
						newSelectedCheckboxValues[item.value] = '';
						this.setState({
							selectedCheckboxValues: newSelectedCheckboxValues,
						});
					}
				});
			} else {
				this.setState({
					value: this.props.initialValue,
				});
			}
		}
	}

	formatAnswers = (answer: string): string => {
		if (answer) {
			const words = answer
				.split(' ')
				.reduce((prev, curr) =>
					prev.concat(curr
						.substring(0, 1)
						.toUpperCase()
						.concat(curr
							.substring(1)
							.toLowerCase())));
			return words.substring(0, 1).toLowerCase().concat(words.substring(1));
		} else {
			return '';
		}

	}

	public render(
		{ errors, handleBlur, handleInput, options, dropdownOptions, ...rest }: Partial<MultiInputProps>,
		{ value, valid, valueMissing, selectedCheckboxValues }: State
	) {
		const finalizedProps: MultiInputProps = {
			errors: {
				valueMissing: 'Please make a selection',
				tooShort: undefined,
				tooLong: undefined,
				patternMismatch: undefined,
				...errors,
			},
			name: 'multi',
			type: 'radio',
			options: options,
			otherPlaceHolder: 'Other: Please Specify',
			disableLabel: false,
			description: '',
			label: 'Please Select',
			placeholder: undefined,
			hasMandatoryTextualInput: false,
			mandatoryTextualInputPlaceholder: 'Enter value here',
			showDropdown: false,
			showRequiredAsterisk: true,
			pattern: undefined,
			required: false,
			elementClass: null,
			min: undefined,
			max: undefined,
			...rest,
		}

		return (
			<fieldset
				class={style.customInput + (finalizedProps.hide ? ` ${style.hide}` : '')}
				ref={this.fieldsetRef} >

				{!finalizedProps.disableLabel &&
					<Fragment>
						<label for={finalizedProps.type}>
							{finalizedProps.label}
							{finalizedProps.tooltip &&
								<div class={style.tooltip}>
									<span class={style.tooltipMark} >?</span>
									<span>{finalizedProps.tooltip}</span>
								</div>}
							{finalizedProps.showRequiredAsterisk && <span><sup class={style.requiredAsterisk}>*</sup></span>}
						</label><br />
					</Fragment>}
				{finalizedProps.description && <p class={style.description}>{finalizedProps.description}</p>}

				{finalizedProps.options.map(item =>
					<div class={style.itemContainer}>
						<input
							type={finalizedProps.type}
							name={finalizedProps.name}
							value={item}
							checked={finalizedProps.type === "radio" ? item === value
								: finalizedProps.type === 'checkbox' ? selectedCheckboxValues.hasOwnProperty(item)
									: (finalizedProps.initialValue && finalizedProps.initialValue.includes(item)) || 
									finalizedProps.initialValueArray && finalizedProps.initialValueArray.some(i => i.name === item)}
									// : finalizedProps.initialValueArray && finalizedProps.initialValueArray.some(i => i.name === item)}
							onBlur={this.handleBlur}
							onChange={this.handleInput}
							required={finalizedProps.required && finalizedProps.type === 'radio'} />
						<span>{item}</span>
						{finalizedProps.hasMandatoryTextualInput &&
							<input
								type="text"
								class={style.checkboxMandatoryText + (selectedCheckboxValues.hasOwnProperty(item) ? ` ${style.active}` : '')}
								name={finalizedProps.name}
								placeholder={finalizedProps.mandatoryTextualInputPlaceholder}
								data-input-type={finalizedProps.type === 'checkbox' ? 'checkbox-mandatory-text' : 'radio-mandatory-text'}
								required={selectedCheckboxValues[item]} />}
						{finalizedProps.showDropdown &&
							<select name={finalizedProps.name} class={finalizedProps.type === 'checkbox' ?
								selectedCheckboxValues.hasOwnProperty(item) ? ` ${style.active}` : '' : 
								value === item ? ` ${style.active}` : ''}>
								<option value="">{finalizedProps.dropdownDefault || '---Please Select---'}</option>
								{dropdownOptions.map((dropDownItem) => {
									return <option selected={selectedCheckboxValues[item] === this.formatAnswers(dropDownItem)} value={this.formatAnswers(dropDownItem)}>{dropDownItem}</option>
								})}
							</select>}
						<br />
					</div>
				)}
				{finalizedProps.type === 'checkbox' && finalizedProps.required &&

					// Unlike radio buttons, checkboxes trigger the validator individually using their "required" attribute, not by the name
					// We cannot set the "required" attribute to all checkboxes - that means we expect the user to tick every single one of them
					// However, it doesn't make sense to just set it on one of the checkboxes since we don't know which one the user would pick
					// This invisible text input helps trigger the validator while not affecting the functionalities of the checkboxes
					<input type='text' name={finalizedProps.name} required={true} value={valid ? 'valid' : ''} style='display: none' />}
				{finalizedProps.hasOther && <input
					type="radio"
					name={finalizedProps.name}
					value={`Other${finalizedProps.acceptTextualOther ? ' (Please specify:)' : ''}`} />}
				{finalizedProps.acceptTextualOther && <input
					type="text"
					name={finalizedProps.name}
					data-input-type={finalizedProps.type === 'checkbox' ? "checkbox-optional" : 'radio-optional'}
					placeholder={finalizedProps.otherPlaceHolder} />}
				<p class={style.inputErrMsg}>{finalizedProps.errors.serverError && <p class={style.inputErrMsg}>{finalizedProps.errors.serverError}{!valid ? `, ` : ''}</p>}
					{(!valid && finalizedProps.required) ?
						valueMissing && <p class={style.inputErrMsg}>{finalizedProps.errors.valueMissing}</p>
						: <p class={style.inputErrMsg}>&nbsp;</p>}</p>
			</fieldset>
		)
	}
}

export class CustomRangeInput extends Component<Partial<Props>, Partial<State>> {
	/**
	 * Call the callback function if it is defined, but set the value when the the user loses focus on the rangeInput
	 * 
	 * @param {Event} event	The event passed when the user loses focus form the RangeInput
	 */
	handleBlur = (event: Event) => {
		if (this.props.handleBlur) this.props.handleBlur(event);
		let inputField: HTMLInputElement = event.target as HTMLInputElement;
		this.setState({ value: inputField.value });
	}

	/**
	 * Set the value after the range input is changed, and call the callback function if it is defined
	 * 
	 * @param {Event} event The Event passed when the inputField is changed
	 */
	handleInput = (event: Event) => {
		if (this.props.handleInput) this.props.handleInput(event);
		let inputField: HTMLInputElement = event.target as HTMLInputElement;
		this.setState({ value: inputField.value });
	}

	/** 
	 * When the input is first rendered, set it to the initial value given by the props 
	 */
	componentDidMount() {
		if (this.props.initialValue) {
			this.setState({ value: this.props.initialValue });
		}
	}
	/**
	 * When the range input updates, the state is automatically updated to reflect the changes of the range input
	 * 
	 * @param prevProps The previous props passed into the function, so an infinite loop doesn't occur
	 */
	componentDidUpdate(prevProps: Props) {
		if (this.props.initialValue && prevProps.initialValue !== this.props.initialValue) {
			this.setState({
				value: this.props.initialValue || this.props.min || 0,
			});
		}
	}

	public render(
		{ errors, handleBlur, handleInput, ...rest }: Partial<Props>,
		{ value }: State
	) {
		const finalizedProps: Partial<Props> = {
			errors: {
				valueMissing: 'Please make a selection',
				tooShort: undefined,
				tooLong: undefined,
				patternMismatch: undefined,
				...errors,
			},
			name: 'range',
			type: 'range',
			disableLabel: false,
			description: '',
			label: 'Please Select',
			showRequiredAsterisk: true,
			required: true,
			elementClass: null,
			min: '0',
			max: '10',
			...rest,
		}
		return (
			<fieldset class={style.customInput + (finalizedProps.hide ? ` ${style.hide}` : '')}>
				{!finalizedProps.disableLabel &&
					<label for={finalizedProps.type}>
						{finalizedProps.label}
						{finalizedProps.tooltip &&
							<div class={style.tooltip}>
								<span class={style.tooltipMark} >?</span>
								<span>{finalizedProps.tooltip}</span>
							</div>}
						{finalizedProps.required && finalizedProps.showRequiredAsterisk && <span><sup class={style.requiredAsterisk}>*</sup></span>}
					</label>}
				{finalizedProps.description && <p class={style.description}>{finalizedProps.description}</p>}

				<div class={style.itemContainer}>
					<input type={finalizedProps.type}
						name={finalizedProps.name}
						required={finalizedProps.required}
						value={value}
						id={finalizedProps.elementId}
						class={finalizedProps.elementClass ? finalizedProps.elementClass : ''}
						onBlur={this.handleBlur}
						onInput={this.handleInput}
						min={finalizedProps.min}
						max={finalizedProps.max}
					/>
					<span>{value}</span>
				</div>
				{finalizedProps.errors.serverError && <p class={style.inputErrMsg}>{finalizedProps.errors.serverError}</p>}
			</fieldset>
		)
	}
}