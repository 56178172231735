import { h, Component } from 'preact';
import * as style from './style.scss';
import StudyInterface from '../../util/studyInterface';

import { Link } from 'react-router-dom';
import { parseDate } from '../../util/date';


interface Props {
	study: StudyInterface;
	onClick?: Function;
}

export default class StudyCard extends Component<Props> {
	public render({ study }: Props) {
		return (
			<Link class={style.studyCard} to={`/studies/${study.id}`}>
				<img src={study.image_url || '../../assets/images/clipboard.png'} />
				<h3 class={style.title}>{study.title}</h3>
				<p class={style.description}>Description: {study.textDescription || 'Coming soon'}</p>
				<div class={style.date}>
					<h6>Dates Open</h6>
					<p>{parseDate(study.start_date, true)} - {parseDate(study.end_date, true)}</p>
				</div>
				<p>{study.is_open}</p>

				{/* <div class={style.participants}>
					<h6>Participants</h6>
					<p>{study.participant_num} / {study.capacity}</p>
				</div> */}
			</Link>
		);
	}
}