import { h } from 'preact';
import { useContext } from 'preact/hooks';
import { Link } from 'react-router-dom';
import * as style from './style.scss';
import { AuthContextState, AuthContext } from '../../util/authContext';

interface Feature {
	imageName: string;
	title: string;
	description: string;
}

const OriginalHome = () => {
	const { userInfo } = useContext<Partial<AuthContextState>>(AuthContext);

	const benefits: Feature[] = [
		{
			imageName: "secure.png",
			title: "Secure",
			description: "We are researchers affiliated with major universities like UCLA and Brown " +
			"University who encrypt your data and NEVER ask your name to ensure your data is safe and not " +
			"shared with anyone outside the research team",
		},
		{
			imageName: "money.png",
			title: "Make Money",
			description: "Our research studies pay you for your time and information",
		},
		{
			imageName: "medical.png",
			title: "Receive Free Services",
			description: "Being a participant in a research study can give you a variety of free services to help your life",
		},
	];

	return (
		<div class={style.home}>
			<header class={style.hero}>
				<div class={style.headerText}>
					<h1>Find studies you want to join.</h1>
				</div>
				{/* Overload the buttons on the landing page based on the type of user account that's currently logged in (if any) */}
				{userInfo ?
					userInfo.is_superuser ? <Link to="/super/studiesList">View All Studies</Link> :
						userInfo.is_researcher ? <Link to="/researcher/studiesList">View Your Studies</Link> :
							<Link to="/participant/studiesList">View Your Studies</Link> // participant
					: <Link to="/studies">View Open Studies</Link>}

				{/* Only display the "Create Account" button when there is no logged-in user */}
				{!userInfo && <Link to="/create">Create Account</Link>}
				<img src={'../../assets/images/hero_with_gradient.jpg'} alt="" />
				<div class={style.sponsor}>
					<p>Sponsored by</p>
					<img src={'../../assets/images/nih.png'} alt="Sponsored by NIH - National Institute of Drug Abuse" />
				</div>
			</header>

			<section class={style.features}>
				{benefits.map(item => <div class={style.feature}>
					<img src={`../../assets/images/${item.imageName}`} alt={item.title} />
					<div class={style.text}>
						<h2>{item.title}</h2>
						<p>{item.description}</p>
					</div>
				</div>)}
			</section>
			<section class={style.video}>
				<h4>A Video from our Creator</h4>
				<video controls playsInline class="wrapper">
					<source src='../../assets/videos/welcome_video.mp4' type='video/mp4'/>
					Video Not Supported
				</video>
			</section>

			<footer>©️2020 Moshemu. All Rights Reserved</footer>
		</div>
	);
}

export default OriginalHome;