/**
 * Converts an ISO-formatted date string to the format of YYYY/MM/DD or MM/DD
 * 
 * @param {string} dateStr The ISO date format string 
 * @param {boolean} [includeYear=false] Whether to include the year in the return
 * 
 * @return {string} The formatted date string
 */
export function parseDate(dateStr: string, includeYear: boolean = false): string {
    return new Date(dateStr).toLocaleDateString('en-US',
        includeYear ? { month: 'numeric', day: 'numeric', year: 'numeric' } : { month: 'numeric', day: 'numeric' })
}