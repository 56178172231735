import { h } from 'preact';
import { useState, useEffect, useContext } from 'preact/hooks';
import * as style from './style.scss';
import { RouteComponentProps, useParams, useHistory, useLocation } from 'react-router-dom';
import { AuthContextConsumer, AuthContextState, AuthContext } from '../../util/authContext';
import CustomForm from '../../components/customForm';
import { formatPhoneNumber } from '../../util/phoneNumber';
import CustomInput, { PasswordInput, EmailInput, TelephoneInput } from '../../components/customInput';
import { toast } from 'react-toastify';
import { API_URL_PREFIX } from '../../util/authContext';
import * as emailjs from 'emailjs-com';

interface State {
	phoneNumber: string,
	firstName: string,
	lastName: string,
	email: string;
	code: string;
	password: string;
	confirmPassword: string;
	confirmationNumber: string;
	onInfoPage: boolean;
	onConfirmPage: boolean;
	onTocPage: boolean;
	isResearcher: boolean;

	emailError: string;
	passwordError: string;
	phoneError: string;
	codeError: string;
}
// class OriginalSignUp extends Component<Partial<RouteComponentProps> & AuthContextState, State> {
const OriginalSignUp = (props: RouteComponentProps & AuthContextState) => {
	const [state, setState] = useState<State>({
		phoneNumber: '',
		firstName: '',
		lastName: '',
		email: '',
		code: '',
		password: '',
		confirmPassword: '',
		confirmationNumber: '',
		onInfoPage: false,
		onConfirmPage: false,
		onTocPage: false,
		isResearcher: undefined,

		emailError: undefined,
		passwordError: undefined,
		phoneError: undefined,
		codeError: undefined,
	});
	/**
	 * Next four functions get rid of server-side errors when blur occurs, since it's assumed they
	 * changed their input value
	 */

	const { isResearcher } = useParams();
	const history = useHistory();
	const location = useLocation();
	const { getUserInfo, getNewToken } = useContext<Partial<AuthContextState>>(AuthContext);

	useEffect(() => {
		isResearcher === "researcher" ?
			setState(currentState => ({ ...currentState, isResearcher: true })) : history.push("/create")
	}, []);

	const emailOnBlur = () => {
		setState(currentState => ({ ...currentState, emailError: undefined }));
	}

	const passwordOnBlur = () => {
		setState(currentState => ({ ...currentState, passwordError: undefined }));
	}

	const phoneOnBlur = () => {
		setState(currentState => ({ ...currentState, phoneError: undefined }));
	}

	const codeOnBlur = () => {
		setState(currentState => ({ ...currentState, codeError: undefined }));
	}

	/**
	 * Takes an event emitted by the password input field and stores the current password in this component's state
	 * This is used to dynamically set the pattern for "Confirm Password" so that error messages can be displayed
	 * even at runtime
	 * 
	 * @param {Event} event The event emitted by te password input field
	 */
	const handlePasswordInput = (event: Event) => {
		if (!event) return;

		const passwordInput: HTMLInputElement = event.target as HTMLInputElement;
		if (passwordInput) {
			setState(currentState => ({ ...currentState, password: passwordInput.value }));
		}
	}

	/**
	 * Takes an array of errors, and concatenates them into 1 error message
	 * @param {string[]} errArray The array of errors
	 * 
	 * @return {string} Returns a string in the form of - error message, error message 2, error message 3
	 */
	const concatenateErrors = (errArray: string[]): string => errArray ? errArray.join(', ') : undefined;

	/**
	 * Takes a user_type string and sends a sign-up notification email using EmailJS
	 * 
	 * @param {string} user_type The type of the user that just signed up. Possible values: "Researcher" and "Participant"
	 */
	const sendSignUpEmail = async (user_type: string): Promise<void> => {
		let templateParams: object = {
			to_email: "ElevateULLC@gmail.com",
			to_name: "Renee",
			user_type: user_type,
			user_name: (state.firstName + " " + state.lastName),
		};

		emailjs.send(
			'gmail',
			'new_user',
			templateParams,
			"user_evjkv2cpOfHpwVl0H4Rpu",
		);
	}

	/**
	 * This function is called when the user finishes entering the basic account information during the sign-up process.
	 * It sends the email and phone number to the server for duplication checking, and only proceeds to the SMS
	 * verification page when everything is good.
	 * 
	 * @param {
	 * {firstName: string, lastName: string, tel: string, email: string, createPassword: string}
	 * } 
	 */
	const handleFirstPage = async ({ firstName, lastName, tel, email, createPassword }) => {
		setState(currentState => ({
			...currentState,
			firstName,
			lastName,
			email,
			password: createPassword,
			phoneNumber: formatPhoneNumber(tel),
		}));

		const payload: object = {
			email: email,
			code: "",
			mobile: formatPhoneNumber(tel),
			password: createPassword,
			first_name: firstName,
			last_name: lastName,
		};
		await fetch(`${API_URL_PREFIX}/user/`, {
			method: 'POST',
			headers: new Headers({ 'Content-Type': 'application/json' }),
			body: JSON.stringify(payload),
		})
			.then(async response => {
				if (response.status === 400) {
					const errors = await response.json();
					if (errors['email'] || errors['mobile'] || errors['password']) {
						setState(currentState => ({
							...currentState,
							phoneError: concatenateErrors(errors['mobile']),
							emailError: concatenateErrors(errors['email']),
							passwordError: concatenateErrors(errors['password']),
						}));
					} else {
						setState(currentState => ({
							...currentState,
							onConfirmPage: !state.onConfirmPage,
						}));

						const mobilePayload: object = {
							mobile: formatPhoneNumber(tel),
						};

						await fetch(`${API_URL_PREFIX}/sms_verification_code/`, {
							method: 'POST',
							headers: new Headers({ 'Content-Type': 'application/json' }),
							body: JSON.stringify(mobilePayload),
						})
							.then(response => response.json())
							.then(() => {
								toast(`You should receive a verification SMS at ${tel} soon`)
							})
					}
				}
			}, error => {
				error;
			})
	}

	/**
	 * This function is called after the user inputs their verification code for their phone number. 
	 * 
	 * @param {code: string} The object that contains the entered SMS verification code
	 */
	const handleSecondPage = async ({ code }: { code: string }) => {
		const payload: object = {
			email: '',
			code: code,
			mobile: state.phoneNumber,
			password: state.password,
			first_name: state.firstName,
			last_name: state.lastName,
			is_researcher: state.isResearcher,
		}
		await fetch(`${API_URL_PREFIX}/user/`, {
			method: 'POST',
			headers: new Headers({ 'Content-Type': 'application/json' }),
			body: JSON.stringify(payload),
		})
			.then(async response => {
				if (response.status === 400) {
					const errors = await response.json();

					if (errors['code']) {
						setState(currentState => ({
							...currentState,
							codeError: concatenateErrors(errors['code']),
						}))
					} else {
						setState(currentState => ({
							...currentState,
							confirmationNumber: code,
							onConfirmPage: !state.onConfirmPage,
							onTocPage: !state.onTocPage,
						}));
					}
				}
			})
			.catch(error => {
				throw error;
			})
	}
	/**
	 * Function that routes the user to the login page when they choose to login instead of creating an account
	 */
	const handleLoginClick = () => {
		history.push('/login');
	}

	/**
	 * This function is called on the Terms and Conditions page. If the user accepts, then their information is sent
	 * to the server and an account is created for them and they are redirected to the questionnaire page.
	 */
	const handleThirdPage = async () => {
		const payload: object = {
			email: state.email,
			code: state.confirmationNumber,
			mobile: state.phoneNumber,
			password: state.password,
			first_name: state.firstName,
			last_name: state.lastName,
			is_researcher: state.isResearcher,
		}
		await fetch(`${API_URL_PREFIX}/user/`, {
			method: 'POST',
			headers: new Headers({ 'Content-Type': 'application/json' }),
			body: JSON.stringify(payload),
		}).catch(error => {
			throw error;
		})
		// TODO: catch when account creation returns errorneous codes
		await getNewToken(state.email, state.password);
		const newUserInfo = await getUserInfo();
		if (newUserInfo) {
			const userGroup: string[] = newUserInfo.groups;
			if (userGroup.includes('Researchers')) {
				toast('Your researcher account has been created!');
				await sendSignUpEmail('researcher');
				location.state && location.state.redirect ? history.push(location.state.redirect, location.state) :
					history.push('/researcher/studiesList');
			} else if (userGroup.includes('Participants')) {
				toast('Your participant account has been created!');
				await sendSignUpEmail('participant');
				location.state && location.state.redirect ? history.push(location.state.redirect, location.state) :
					history.push('/participant/questionnaire', { fromSignUp: true });
			} else {
				throw `An Error occured - unexpected user permissions: ${userGroup}`;
			}
		} else {
			toast('Oops, we encountered some trouble logging into your new account. Please try again');
		}
	}

	// Traverse through the pages in reverse order until the user reachs the beginning, where the user is
	// pushed to the landing page after clicking cancel again
	const cancel = async () => {
		if (state.onTocPage) {
			setState(currentState => ({ ...currentState, onTocPage: !state.onTocPage }));
		}
		else if (state.onConfirmPage) {
			setState(currentState => ({ ...currentState, onConfirmPage: !state.onConfirmPage }));
		} else if (state.onInfoPage) {
			setState(currentState => ({
				...currentState,
				isResearcher: undefined,
				onInfoPage: !state.onInfoPage,
			}));
		} else {
			history.push('/');
		}
	}

	const { emailError, phoneError, passwordError, codeError } = state;

	return (
		<div class={style.signUp}>
			<div class={style.box}>
				<h1>Create {state.isResearcher === undefined ? "an" : state.isResearcher ? 'Researcher' : 'Participant'} Account</h1>
				<div class={style.displayWindow + (state.onTocPage ? ` ${style.pageThree}` : state.onConfirmPage ? ` ${style.pageTwo}` : ` ${style.pageOne}`)}>

					<CustomForm
						submitText="Next"
						cancelCallback={cancel}
						submitCallback={handleFirstPage}
						class={style.firstPage}>
						<div class={style.twoColumn}>
							<CustomInput
								type="name"
								name="firstName"
								label="First Name"
								placeholder="First Name"
								errors={{
									valueMissing: 'First name is required'
								}} />
							<CustomInput
								type="name"
								name="lastName"
								label="Last Name"
								placeholder="Last Name"
								errors={{
									valueMissing: 'Last name is required'
								}} />
						</div>
						<TelephoneInput
							tooltip="We use your phone number to ensure that one person can only create one account."
							handleBlur={phoneOnBlur}
							errors={{
								serverError: phoneError,
							}} />
						<EmailInput handleBlur={emailOnBlur}
							errors={{
								serverError: emailError
							}} />
						<div class={style.twoColumn}>
							<PasswordInput
								name="createPassword"
								label="Create Password"
								pattern="(?=.*\d)(?=.*[a-zA-Z]).{8,}"
								errors={{
									patternMismatch: 'Your password should contain at least 8 characters with numbers and letters',
									serverError: passwordError
								}}
								handleInput={handlePasswordInput}
								handleBlur={passwordOnBlur} />
							<PasswordInput
								name="confirmPassword"
								label="Confirm Password"
								pattern={state.password}
								errors={{ patternMismatch: 'The two passwords must be the same' }} />
						</div>
					</CustomForm>

					<CustomForm
						submitText="Next"
						cancelCallback={cancel}
						submitCallback={handleSecondPage}
						class={style.secondPage}>
						<CustomInput
							type="number"
							label="SMS Verification Code"
							name="code"
							pattern="[0-9]{6}"
							handleBlur={codeOnBlur}
							placeholder="Enter your 6-digit Verification Code"
							errors={{
								patternMismatch: 'Please enter a valid verification code',
								serverError: codeError
							}} />
					</CustomForm>

					<CustomForm
						submitText="I Agree"
						cancelCallback={cancel}
						submitCallback={handleThirdPage}
						class={style.thirdPage}
						useAsyncButton={true}>
						{/* The rest of this file is the Terms and Conditions of the website */}
						<div class={style.tosContainer}>
							<h3>Section 1. Terms of Use</h3>
							<ul>
								<li>The Moshemu website is a web-based tool that was established by ElevateU LLC
								to help match researchers with research participants. The Moshemu website
								(accessible at “Moshemu.com”) contains a volunteer registry available to any
								individual who wishes to express interest in participating in research projects
								across the United States. The Moshemu website is owned and operated by ElevateU LLC,
								and its services are offered to you under the terms and conditions described in this
								Terms of Use. Your use of the Moshemu website constitutes your agreement to be bound
								by this Terms of Use. If you do not agree to the following Terms of Use, you may not
										use the Moshemu website.</li>
							</ul>
							<h3>Section 2. Use of Services</h3>
							<ul>
								<li>NOT FOR EMERGENCIES OR MEDICAL OR PROFESSIONAL ADVICE</li>
								<ul>
									<li>The Moshemu website does not provide any medical or healthcare
									products, services or advice, and is not for medical emergencies
									or urgent situations. IF YOU THINK YOU MAY HAVE A MEDICAL EMERGENCY,
									CALL YOUR DOCTOR OR 911 IMMEDIATELY. Also, information contained on
									this website is not a substitute for a doctor's medical judgment or
									advice. Moshemu recommends that you discuss your specific, individual
									health concerns with your doctor or health care professional. The information
									and services offered on the Moshemu website are designed to match individuals
									within the public who may be interested in participating in research with
									appropriate studies being conducted by eligible researchers utilizing Moshemu
										registry as a study recruitment tool.</li>
								</ul>
								<li>Cost</li>
								<ul>
									<li>There is no charge for using the Moshemu website at this time.</li>
								</ul>
								<li>Your Account</li>
								<ul>
									<li>Upon registration, you will sign in with a phone number and email address.
									You are solely responsible for maintaining the confidentiality of your unique
									login and password and for all activities that occur under your username and
									password. You agree to prohibit anyone else from using your login and password
									and will immediately notify Moshemu and your Institutional Liaison, if applicable,
									of any unauthorized use of your login and password or other security concerns of
											which you become aware.</li>
									<li>You agree that Moshemu may access, preserve, and disclose your account information,
									including your login and password, if required to do so by law or in good faith
									belief that it is necessary to: (i) enforce this Terms of Use, (ii) respond to your
									requests for customer service, (iii) comply with federal, state, or local laws or
									legal process, (iv) respond to claims that information you have provided violates
									the rights of others, or (v) protect the rights, property or personal safety of
									Moshemu and its users or the public. Furthermore, you represent and warrant that
									you reside in the United States, are at least 18 years of age, and that you possess
									the legal right and ability to enter into this Terms of Use and abide by the
									obligations hereunder. You agree to use the Moshemu website in accordance with this
									Terms of Use and abide by the obligations hereunder. You are responsible for
									maintaining the confidentiality of your login information and for all activities
									that occur under your login. You agree to prohibit anyone else from using your account
									and will immediately notify Moshemu of any unauthorized use of your password or other
											security concerns of which you may become aware.</li>
								</ul>
								<li>Intellectual Property</li>
								<ul>
									<li>Moshemu hereby grants you a limited, revocable, non-transferable and non-exclusive
									license limited solely to viewing or downloading a single copy of the material on
									the Moshemu website and to use the Moshemu website to the extent necessary to use the
									Moshemu services solely for their intended purposes. With the exception of any personal
									information submitted by a potential research volunteer, ElevateU retains all right,
									title and interest in and to Moshemu website, the Moshemu services and any content,
									products, documentation, or other materials on the Moshemu website and any patent,
									copyright, trade secret, trademark, service mark, or other intellectual property or
									proprietary right in any of the foregoing. With the exception of any personal information
									submitted by a potential research volunteer, the information available through the Moshemu
									website is the property of ElevateU and its network sites and is protected by United States
									copyright, trademark, and other intellectual property laws; and any copy made of such
									information obtained from the Moshemu website as provided herein must include the copyright
									and/or trademark notice when applicable. You agree not to reproduce, retransmit, distribute,
									disseminate, sell, publish, broadcast, or circulate the information owned by Moshemu, or
									received from any other party or individual through the Moshemu website or registry to anyone,
									including but not limited to others in your organization. Use, reproduction, copying, or
									redistribution of the Moshemu or ElevateU name, mark, or logo or any of its participating
									sites' name, mark, or logo is strictly prohibited without written permission from ElevateU
											or its participating sites.</li>
								</ul>
								<li>Third Party Sites</li>
								<ul>
									<li>Although Moshemu may include links providing direct access to third-party Internet sites as a
									convenience, the inclusion of a link does not imply endorsement of the linked site by Moshemu
									or any of its participating sites. Moshemu takes no responsibility for the content or information
									contained on those other sites, and does not exert any editorial or other control over those
									other sites. Nor does Moshemu take responsibility for the privacy policies and practices of
											these third-party links.</li>
								</ul>
								<li>Operation and Record Retention</li>
								<ul>
									<li>ElevateU reserves complete and sole discretion with respect to the operation of Moshemu website.
									Moshemu may, among other things withdraw, suspend or discontinue any functionality or feature of
									Moshemu features or services. Moshemu is not responsible for transmission errors or corruption or
									compromise of data carried over local or interchange telecommunication carriers. Moshemu is not
									responsible for maintaining data arising from use of the Moshemu services. Moshemu reserves the
											right to maintain, delete or destroy all communications and materials posted or uploaded to Moshemu.</li>
								</ul>
								<li>Limitations of Use</li>
								<ul>
									<li>You agree not to access or use the Moshemu website in an unlawful way or for any unlawful purpose
									or for any purposes other than its intended purposes. You agree not to post or transmit:
									(a) a message under a false name; or (b) any information which (i) is libelous, defamatory,
									obscene, fraudulent, false or contrary to the ownership or intellectual property rights of
									any other person, or (ii) contains any virus, worm, Trojan horse or other code which is
									contaminating or destructive to the files or programs of Moshemu or any of its users.
									Moshemu reserves the right to delete any information provided by you that it deems in its sole
									discretion fraudulent, abusive, defamatory, obscene or in violation of a copyright, trademark
											or other intellectual property or ownership right of any other person.</li>
								</ul>
							</ul>
							<h3>Section 3. Disclaimers</h3>
							<ul>
								<li>General Disclaimers</li>
								<ul>
									<li>YOU ACKNOWLEDGE THAT YOUR USE OF THE MOSHEMU WEBSITE IS AT YOUR SOLE RISK, AND THAT YOU ASSUME
									FULL RESPONSIBILITY FOR ALL RISK ASSOCIATED THEREWITH. ALL INFORMATION, PRODUCTS OR SERVICES
									CONTAINED ON OR PROVIDED THROUGH THIS WEBSITE ARE PROVIDED "AS IS" WITHOUT ANY WARRANTY OF ANY
									KIND, EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW ELEVATEU LLC
									UNIVERSITY AND ITS AFFILIATES, DIRECTORS, OFFICERS, MANAGERS, EMPLOYEES OR OTHER REPRESENTATIVES
									(COLLECTIVELY, "AFFILIATES") HEREBY DISCLAIM ALL REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED,
									STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
									PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT AND FREEDOM FROM COMPUTER VIRUS STRAINS. WITHOUT LIMITING
									THE FOREGOING, ELEVATEU AND ITS AFFILIATES MAKE NO WARRANTY AS TO THE RELIABILITY, ACCURACY, TIMELINESS,
									USEFULNESS, ADEQUACY, COMPLETENESS OR SUITABILITY OF THE MOSHEMU WEBSITE, INFORMATION RECEIVED THROUGH
											THE MOSHEMU WEBSITE, AND OTHER SERVICES PROVIDED HEREUNDER.</li>
								</ul>
								<li>Limitation of Liability</li>
								<ul>
									<li>IN NO EVENT SHALL MOSHEMU OR ELEVATEU BE LIABLE FOR ANY DAMAGES OR OTHER LIABILITY TO YOU OR ANY
									OTHER USERS OF THE ELEVATEU WEBSITE.  TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL
									MOSHEMU OR ELEVATEU OR ANY OF ITS AFFILIATES BE LIABLE FOR ANY SPECIAL, PUNITIVE, INDIRECT,
									INCIDENTAL OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO PERSONAL INJURY, WRONGFUL
									DEATH, LOSS OF GOODWILL, LOSS OF USE, LOSS OF PROFITS, INTERRUPTION OF SERVICE OR LOSS OF DATA,
									WHETHER IN ANY ACTION IN WARRANTY, CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO NEGLIGENCE OR
									FUNDAMENTAL BREACH), OR OTHERWISE ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF, OR THE
									INABILITY TO USE, THIS WEBSITE OR ANY SERVICE OFFERED THROUGH THIS WEBSITE OR ANY MATERIAL OR
									INFORMATION CONTAINED IN, ACCESSED THROUGH, OR PRODUCTS OR SERVICES OBTAINED THROUGH THIS
									WEBSITE, EVEN IF AN AUTHORIZED REPRESENTATIVE OF MOSHEMU IS ADVISED OF THE LIKELIHOOD OR
									POSSIBILITY OF THE SAME. TO THE EXTENT ANY OF THE ABOVE LIMITATIONS OF LIABILITY ARE RESTRICTED
									BY APPLICABLE FEDERAL, STATE OR LOCAL LAW, SUCH LIMITATIONS SHALL NOT APPLY TO THE EXTENT OF
											SUCH RESTRICTIONS.</li>
								</ul>
							</ul>
							<h3>Section 4. General Provisions</h3>
							<ul>
								<li>Indemnity</li>
								<ul>
									<li>YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS MOSHEMU, ELEVATEU AND ITS EMPLOYEES, AND
									AGENTS FROM AND AGAINST ALL LOSSES, LIABILITY, EXPENSES, DAMAGES AND COSTS, INCLUDING REASONABLE
									ATTORNEY'S FEES, ARISING OUT OF OR RELATED TO ANY BREACH OF THIS TERMS OF USE, ANY NEGLIGENT
									OR WRONGFUL ACTION OR OMISSION BY YOU RELATED TO YOUR USE OF OR PROVIDING OF SERVICES THROUGH
									THE MOSHEMU WEBSITE, OR ANY NEGLIGENT OR WRONGFUL USE OF THE MOSHEMU WEBSITE (INCLUDING,
									WITHOUT LIMITATION, INFRINGEMENT OF THIRD PARTY INTELLECTUAL PROPERTY RIGHTS OR NEGLIGENT OR
											WRONGFUL CONDUCT) BY YOU OR ANY OTHER PERSON ACCESSING YOUR ACCOUNT.</li>
								</ul>
								<li>Third Party Rights</li>
								<ul>
									<li>The indemnification provisions set forth above and the provisions under Section 3 Disclaimers
									are for the benefit of Vanderbilt University and its affiliates. Each of these individuals or
									entities shall have the right to assert and enforce those provisions directly against you on
											its own behalf.</li>
								</ul>
								<li>Term and Termination</li>
								<ul>
									<li>This Terms of Use shall commence upon the date you first access or use Moshemu website.
									Either you Moshemu may terminate this Terms of Use and your right to use the Moshemu website
									at any time, with or without cause. This Terms of Use and the licenses granted hereunder
									shall terminate without notice in the event you (or any authorized person using your account)
									fail to comply with the terms and conditions of this Terms of Use or the Moshemu registry
											posted on the Moshemu website from time to time.</li>
								</ul>
								<li>Notice</li>
								<ul>
									<li>Moshemu may provide notice by e-mail to the e-mail address you provided Moshemu by a general
									notice on the Moshemu website, or by written communication delivered by first class U. S.
									mail or express courier to your address on record in the Moshemu account information. You
											may give notice to Moshemu at any time via electronic mail to “ElevateULLC@gmail.com”, </li>
									<li>This Terms of Use shall be governed by and construed in accordance with the laws
									of the State of California without giving effect to any choice of law rules or
									principles. You agree that Orange County, California shall be the sole and
									exclusive venue for any legal action or proceeding between Moshemu or ElevateU
									and you for any purpose concerning this Terms of Use. Any cause of action or claim
									you may have with respect to the use of the Moshemu website must be commenced within
									one (1) year after it arises, except to the extent such limitation is not enforceable.
									To the fullest extent permitted by law, each party to this Terms of Use waives its or
									his or her right to a jury trial with respect to any dispute or other controversy arising
											from hereunder or your use of or access to the Moshemu web site.</li>
								</ul>
								<li>Severability</li>
								<ul>
									<li>The provisions of this Terms of Use are severable, and in the event any provision
									hereof is determined to be invalid or unenforceable, such invalidity or
									unenforceability shall not affect the validity or enforceability of the remaining
									provisions, but such provision shall be reformed, if reasonably possible, only to
											the extent necessary to make it enforceable.</li>
								</ul>
								<li>Waiver</li>
								<ul>
									<li>If Moshemu does not exercise or enforce any particular right or provision in these
									Terms, Moshemu does not waive that right or provision. Failure to exercise or delay
									in exercising any right hereunder, or failure to insist upon or enforce strict
									performance of any provision of this Terms of Use, shall not be considered waiver
									thereof, which can only be made by a signed writing. No single waiver shall be
											considered a continuing or permanent waiver.</li>
								</ul>
								<li>Assignment</li>
								<ul>
									<li>You may not assign, transfer, or delegate this Terms of Use or any part of it without
									the prior written consent of Moshemu. Moshemu may freely transfer, assign or delegate
									all or any part of this Terms of Use and any rights and duties hereunder. This Terms of
									Use will be binding upon and inure to the benefit of the heirs, successors and permitted
											assigns of the parties.</li>
								</ul>
								<li>Change to Terms of Use</li>
								<ul>
									<li>Moshemu reserves the right to update or change these Terms of Use at any time and
									for any reason by posting the modified Terms of Use. Your continued use constitutes
									your agreement to be bound by any such revisions and you should therefore periodically
									visit this page of the website and print the latest version of the Terms of Use for your
									records. The date of the last update to these Terms of Use is stated at the top of this
											document. All Terms are automatically effective as soon as they are posted.</li>
								</ul>
								<li>Research reported in this publication was supported by the National Institute On Drug abuse of
								the National Institutes of Health under Award Number R43DA048670. The content is solely the
								responsibility of the authors and does not necessarily represent the official views of the National
								Institutes of Health.
									</li>
							</ul>
							<ul>
								<li>Thank you for reading the Moshemu Terms of Use.</li>
							</ul>
						</div>
					</CustomForm>
				</div>
			</div>
		</div>
	);
}

// Without type coercion, TypeScript will complain since withRouter from react-router-dom 
// only accepts React components, not preact, even though deep down it works just fine
export default AuthContextConsumer(OriginalSignUp);