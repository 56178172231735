import { h } from 'preact';
import * as style from './style.scss';

const About = () => (
    <article class={`wrapper ${style.about}`}>
        <h2>About Us</h2>
        <figure>
            <img src='../assets/images/about_photo_renee.jpg' alt='Renee Garett' />
            <figcaption>Renee Garett, a Licensed Clinical Social Worker and UCLA social work lecturer from California</figcaption>
        </figure>

        <section>
            <p>
                Moshemu is the brainchild of Renee Garett, a Licensed Clinical Social Worker and
                UCLA social work lecturer from California who realized that conducting research
                studies to help substance users was a very difficult and slow process.
            </p>
        </section>
        <section>
            <p>
                Renee started her career in social work as a social work trainee at a homeless shelter
                in Skid Row where she worked as a counselor for heroin and cocaine users. For the past
                11 years Renee has worked in many different settings (non-profits, community mental health
                settings, and in her own private office) and she realized more needed to be done to help those
                who use substances.
            </p>
        </section>
        <section>
            <p>
                Without research, it is impossible to find new medications and treatment options to help substance
                users and to examine and learn how substances affect the body.
            </p>
        </section>
        <section>
            <p>
                Renee wrote to the National Institute of Drug Abuse  (NIDA) and proposed an idea to create the first
                ever-online platform to match researchers with substance users who want to participate in research.
                NIDA agreed this was an important idea and funded the creation of Moshemu.
            </p>
        </section>
        <section>
            <p>
                Thank you for reading our story and thank you for being apart of the Mosehmu Community by signing up
                and creating an anonymous account. If you have any questions please do not hesitate to email Renee at:
                &nbsp;<a href='mailto: ReneeGarettLCSW@gmail.com'>ReneeGarettLCSW@gmail.com</a>
            </p>
        </section>
    </article>
)

export default About;