import { h, Component } from 'preact';
import * as style from './style.scss';

interface Props {}
interface State {}

export default class Form extends Component<Props, State> {
	public render() {
		return (
			<div class={style.form}>
        		<div class={`wrapper ${style.contentContainer}`}>
				<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdS9wfsWDW5P_e2qhFMfFoJujCcR7ozdrl79edgMOaBQ_1DQw/viewform?embedded=true" frameBorder="0" marginHeight={0} marginWidth={0}>Loading…</iframe>
				</div>
			</div>
		);
	} 
}