import { h, Component, Fragment } from 'preact';
import * as style from './style.scss';
import LoadingSpinner from '../loadingSpinner';

interface Props {
    text?: string;
    pendingText?: string;
    successText?: string;
    callback: Function;
}

interface State {
    pending: boolean;
}

export default class AsyncButton extends Component<Props & Partial<h.JSX.HTMLAttributes>, State> {
    state: State = {
        pending: null,
    }

    unmounted: boolean = false;

    handleAsyncButtonClick = async (event: Event) => {
        this.setState({
            pending: true,
        }, async () => {
            await this.props.callback(event);
            if (!this.unmounted) {
                this.setState({
                    pending: false,
                });
            }
        });
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    public render({ text, pendingText, successText, callback, ...rest }: Props, { pending }: State) {
        return (
            <button class={style.asyncButton + (pending ? ` ${style.pendingButton}` : '')} onClick={this.handleAsyncButtonClick} 
                disabled={pending} type="button" {...rest}>
                {pending ?
                    <Fragment>
                        <LoadingSpinner size={36} />
                        <span>{pendingText || 'Loading...'}</span>
                    </Fragment> : text || 'Submit'
                }
            </button>
        )
    }
}