import { h, Component, createRef } from 'preact';
import * as style from './style.scss';
import { toast } from 'react-toastify';
import AsyncButton from '../asyncButton';

interface Props {
	cancelText?: string;
	submitText?: string;
	cancelCallback?: Function;
	submitCallback?: Function;
	class?: string;
	id?: string;
	hideButton?: boolean;
	noValidityCheck?: boolean;
	resetAfterSubmit?: boolean;
	useAsyncButton?: boolean;
	children: any;
}

export default class CustomForm extends Component<Props> {
	formRef = createRef();

	/**
	 * When the form is submitted, go through every single input and grab the current value(s)
	 * @param {Event} event The submit event emitted by this CustomForm
	 */
	onFormSubmit = (event: Event) => {
		/* Skips this function when the AsyncButton is used to avoid double calling
		Double calling occurs when the user hits the enter key: both onFormSubmit()
		and onAsyncFormSubmit() are called */
		if (!event || this.props.useAsyncButton) return;
		event.preventDefault();
		if (event.stopPropagation) event.stopPropagation();
		if (event.stopImmediatePropagation) event.stopImmediatePropagation();

		const domForm: HTMLFormElement = event.target as HTMLFormElement;
		
		const formIsValid: boolean = this.checkValidity(domForm);
		// If there is a submission callback, run that submission callback with the full result of the form
		if (formIsValid && this.props.submitCallback) {
			this.props.submitCallback(this.gatherFormResult(domForm));
		}

		if (this.props.resetAfterSubmit) domForm.reset();
		
	}

	/**
	 * Same as the function above but only used in conjunction with the AsyncButton
	 */
	onAsyncFormSubmit = async (event: Event) => {
		if (!event) return;
		event.preventDefault();
		if (event.stopPropagation) event.stopPropagation();
		if (event.stopImmediatePropagation) event.stopImmediatePropagation();

		const button: HTMLButtonElement = event.target as HTMLButtonElement;
		const domForm: HTMLFormElement = button.closest('form');
		const formIsValid: boolean = this.checkValidity(domForm);
		// If there is a submission callback, run that submission callback with the full result of the form
		if (formIsValid && this.props.submitCallback) {
			await this.props.submitCallback(this.gatherFormResult(domForm));
		}

		if (this.props.resetAfterSubmit) domForm.reset();
	}

	/**
	 * Takes a DOM element that represents the form and returns the form field values
	 * 
	 * @param {HTMLFormElement} domForm The DOM form element
	 * @return {any} The form field values contained in an object 
	 */
	gatherFormResult = (domForm: HTMLFormElement): any => {
		let result = {};
		new FormData(domForm).forEach((value, key) => result[key] = value);

		// The following code block makes it possible for us to manually add
		// ALL selected fields for a given set of checkboxes to the FormData result
		const fieldSets = domForm.querySelectorAll('fieldset');
		fieldSets.forEach(fieldSet => {
			//Separate out all types of inputs in order to deal with them individually
			const checkboxes = fieldSet.querySelectorAll('input[type=checkbox]');
			const radioOptions = fieldSet.querySelectorAll('input[type=radio]');
			const textualInputs: HTMLInputElement[] = checkboxes.length !== 0 ?
				fieldSet.querySelectorAll('input[data-input-type=checkbox-mandatory-text]') as any :
				fieldSet.querySelectorAll('input[data-input-type=radio-mandatory-text]') as any;
			const dropdowns: HTMLSelectElement[] = fieldSet.querySelectorAll('select') as any;

			if (checkboxes.length !== 0) {
				let values = this.gatherAssociatedValues(checkboxes, textualInputs, dropdowns)

				const optional: HTMLInputElement = fieldSet.querySelector('input[data-input-type=checkbox-optional]');
				if (optional && optional.value) values.push({ name: optional.value });
				result[checkboxes[0].getAttribute('name')] = values;
			} else if (radioOptions.length !== 0) {
				let values = this.gatherAssociatedValues(radioOptions, textualInputs, dropdowns)

				const optional: HTMLInputElement = fieldSet.querySelector('input[data-input-type=radio-optional]');
				if (optional && optional.value) values.push({ name: optional.value });
				result[radioOptions[0].getAttribute('name')] = values;
			}
		})
		return result;
	}

	/**
	 * This function takes a list of radio or checkbox inputs, along with arrays of optional textual inputs and dropdowns.
	 * It returns an array of objects that couples the optional textual input values and dropdown values with the radio or
	 * checkbox input
	 * 
	 * @param {NodeListOf<Element>} multiInputFields The list of checkbox or radio input elements
	 * @param {HTMLInputElement[]} textualInputs An array of optional textual input elements
	 * @param {HTMLSelectElement[]} dropdowns An array of optional dropdown select input elements
	 * @returns {object[]} The array of coupled values that includes the optional textual or dropdown values
	 */
	gatherAssociatedValues = (multiInputFields: NodeListOf<Element>, textualInputs: HTMLInputElement[], dropdowns: HTMLSelectElement[]): object[] => {
		let values: object[] = [];

		// Adds the textual inputs following multiInputs (e.g. frequency for substance use) to the formData
		if (textualInputs.length !== 0) {
			multiInputFields.forEach((checkbox: HTMLInputElement, index: number) => {
				values.push({
					name: checkbox.value,
					value: checkbox.checked,
					frequency: textualInputs[index].value,
				});
			});
		} else if (dropdowns.length !== 0) {
			// Adds the dropdown inputs following multiInputs (e.g. diagnosis time for health condition) to the formData
			multiInputFields.forEach((checkbox: HTMLInputElement, index: number) => {
				const dropdown: HTMLSelectElement = dropdowns[index];
				const option: HTMLOptionElement = dropdown[dropdown.selectedIndex] as any;
				values.push({
					name: checkbox.value,
					value: checkbox.checked,
					frequency: option.value,
				});
			});
		} else {
			multiInputFields.forEach((checkbox: HTMLInputElement) => {
				values.push({
					name: checkbox.value,
					value: checkbox.checked,
				});
			});
		}

		return values;
	}

	/**
	 * Takes a DOM element that represents the form and uses the HTML5 ValidityState to check 
	 * whether the form is valid. If the form is valid, returns true. If not, toasts an error
	 * message and triggers a blur event for every input field to toggle their own error
	 * messages, if any.
	 * 
	 * @param {HTMLFormElement} domForm The DOM form element 
	 * @return {boolean} Whether the form is valid or not
	 */
	checkValidity = (domForm: HTMLFormElement = this.formRef.current): boolean => {
		// Uses the HTML5 ValidityState for validity checking
		if (!domForm.checkValidity() && !this.props.noValidityCheck) {
			const formElements = domForm.elements;
			// TODO: Support more types of error messages
			toast("One or more fields are invalid. Please ensure all required fields are filled out")
			for (let i = 0; i < formElements.length; ++i) {
				const formElement: Element = formElements[i];
				// Manually trigger a blur event for all inputs on submit to toggle error messages, if any
				if (formElement.tagName === 'INPUT') {
					formElement.dispatchEvent(new Event('blur'));
				}
			}
			return false;
		}
		// Form is valid
		return true;
	}

	/**
	 * Triggers the cancel callback when passed with one
	 */
	onFormCancel = () => {
		if (this.props.cancelCallback) {
			this.props.cancelCallback();
		}
	}

	public render({ children, cancelText, submitText, class: customClass, cancelCallback, submitCallback, hideButton, useAsyncButton, ...rest }: Props) {
		return (
			<form class={style.customForm + (customClass ? ` ${customClass}` : '')} onSubmit={this.onFormSubmit} ref={this.formRef} {...rest} noValidate>
				{children}
				{/* Parent components can use custom buttons that are bound to this form as well */}
				{!hideButton &&
					<div class={style.buttonContainer}>
						<button type="button" name="cancel" onClick={this.onFormCancel}>{cancelText || 'Cancel'}</button>
						{useAsyncButton ? <AsyncButton callback={e => this.onAsyncFormSubmit(e)} type="submit" /> : <button type="submit" name="submit">{submitText || 'Submit'}</button>}
					</div>}
			</form>
		);
	}
}
