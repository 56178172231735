import { h, Component } from 'preact';
import * as style from './style.scss';

interface Props {}
interface State {}

const TermsAndConditions = () => (
			<div class={`${style.termsAndConditions}`}>
				<div class={`wrapper ${style.contentContainer}`}>
				<h2>Terms and Conditions</h2>
				<h3>Section 1. Terms of Use</h3>
					<ul>
						<li>The Moshemu website is a web-based tool that was established by ElevateU LLC
						to help match researchers with research participants. The Moshemu website
						(accessible at “Moshemu.com”) contains a volunteer registry available to any
						individual who wishes to express interest in participating in research projects
						across the United States. The Moshemu website is owned and operated by ElevateU LLC,
						and its services are offered to you under the terms and conditions described in this
						Terms of Use. Your use of the Moshemu website constitutes your agreement to be bound
						by this Terms of Use. If you do not agree to the following Terms of Use, you may not
								use the Moshemu website.</li>
					</ul>
					<h3>Section 2. Use of Services</h3>
					<ul>
						<li>NOT FOR EMERGENCIES OR MEDICAL OR PROFESSIONAL ADVICE</li>
						<ul>
							<li>The Moshemu website does not provide any medical or healthcare
							products, services or advice, and is not for medical emergencies
							or urgent situations. IF YOU THINK YOU MAY HAVE A MEDICAL EMERGENCY,
							CALL YOUR DOCTOR OR 911 IMMEDIATELY. Also, information contained on
							this website is not a substitute for a doctor's medical judgment or
							advice. Moshemu recommends that you discuss your specific, individual
							health concerns with your doctor or health care professional. The information
							and services offered on the Moshemu website are designed to match individuals
							within the public who may be interested in participating in research with
							appropriate studies being conducted by eligible researchers utilizing Moshemu
								registry as a study recruitment tool.</li>
						</ul>
						<li>Cost</li>
						<ul>
							<li>There is no charge for using the Moshemu website at this time.</li>
						</ul>
						<li>Your Account</li>
						<ul>
							<li>Upon registration, you will sign in with a phone number and email address.
							You are solely responsible for maintaining the confidentiality of your unique
							login and password and for all activities that occur under your username and
							password. You agree to prohibit anyone else from using your login and password
							and will immediately notify Moshemu and your Institutional Liaison, if applicable,
							of any unauthorized use of your login and password or other security concerns of
									which you become aware.</li>
							<li>You agree that Moshemu may access, preserve, and disclose your account information,
							including your login and password, if required to do so by law or in good faith
							belief that it is necessary to: (i) enforce this Terms of Use, (ii) respond to your
							requests for customer service, (iii) comply with federal, state, or local laws or
							legal process, (iv) respond to claims that information you have provided violates
							the rights of others, or (v) protect the rights, property or personal safety of
							Moshemu and its users or the public. Furthermore, you represent and warrant that
							you reside in the United States, are at least 18 years of age, and that you possess
							the legal right and ability to enter into this Terms of Use and abide by the
							obligations hereunder. You agree to use the Moshemu website in accordance with this
							Terms of Use and abide by the obligations hereunder. You are responsible for
							maintaining the confidentiality of your login information and for all activities
							that occur under your login. You agree to prohibit anyone else from using your account
							and will immediately notify Moshemu of any unauthorized use of your password or other
									security concerns of which you may become aware.</li>
						</ul>
						<li>Intellectual Property</li>
						<ul>
							<li>Moshemu hereby grants you a limited, revocable, non-transferable and non-exclusive
							license limited solely to viewing or downloading a single copy of the material on
							the Moshemu website and to use the Moshemu website to the extent necessary to use the
							Moshemu services solely for their intended purposes. With the exception of any personal
							information submitted by a potential research volunteer, ElevateU retains all right,
							title and interest in and to Moshemu website, the Moshemu services and any content,
							products, documentation, or other materials on the Moshemu website and any patent,
							copyright, trade secret, trademark, service mark, or other intellectual property or
							proprietary right in any of the foregoing. With the exception of any personal information
							submitted by a potential research volunteer, the information available through the Moshemu
							website is the property of ElevateU and its network sites and is protected by United States
							copyright, trademark, and other intellectual property laws; and any copy made of such
							information obtained from the Moshemu website as provided herein must include the copyright
							and/or trademark notice when applicable. You agree not to reproduce, retransmit, distribute,
							disseminate, sell, publish, broadcast, or circulate the information owned by Moshemu, or
							received from any other party or individual through the Moshemu website or registry to anyone,
							including but not limited to others in your organization. Use, reproduction, copying, or
							redistribution of the Moshemu or ElevateU name, mark, or logo or any of its participating
							sites' name, mark, or logo is strictly prohibited without written permission from ElevateU
									or its participating sites.</li>
						</ul>
						<li>Third Party Sites</li>
						<ul>
							<li>Although Moshemu may include links providing direct access to third-party Internet sites as a
							convenience, the inclusion of a link does not imply endorsement of the linked site by Moshemu
							or any of its participating sites. Moshemu takes no responsibility for the content or information
							contained on those other sites, and does not exert any editorial or other control over those
							other sites. Nor does Moshemu take responsibility for the privacy policies and practices of
									these third-party links.</li>
						</ul>
						<li>Operation and Record Retention</li>
						<ul>
							<li>ElevateU reserves complete and sole discretion with respect to the operation of Moshemu website.
							Moshemu may, among other things withdraw, suspend or discontinue any functionality or feature of
							Moshemu features or services. Moshemu is not responsible for transmission errors or corruption or
							compromise of data carried over local or interchange telecommunication carriers. Moshemu is not
							responsible for maintaining data arising from use of the Moshemu services. Moshemu reserves the
									right to maintain, delete or destroy all communications and materials posted or uploaded to Moshemu.</li>
						</ul>
						<li>Limitations of Use</li>
						<ul>
							<li>You agree not to access or use the Moshemu website in an unlawful way or for any unlawful purpose
							or for any purposes other than its intended purposes. You agree not to post or transmit:
							(a) a message under a false name; or (b) any information which (i) is libelous, defamatory,
							obscene, fraudulent, false or contrary to the ownership or intellectual property rights of
							any other person, or (ii) contains any virus, worm, Trojan horse or other code which is
							contaminating or destructive to the files or programs of Moshemu or any of its users.
							Moshemu reserves the right to delete any information provided by you that it deems in its sole
							discretion fraudulent, abusive, defamatory, obscene or in violation of a copyright, trademark
									or other intellectual property or ownership right of any other person.</li>
						</ul>
					</ul>
					<h3>Section 3. Disclaimers</h3>
					<ul>
						<li>General Disclaimers</li>
						<ul>
							<li>YOU ACKNOWLEDGE THAT YOUR USE OF THE MOSHEMU WEBSITE IS AT YOUR SOLE RISK, AND THAT YOU ASSUME
							FULL RESPONSIBILITY FOR ALL RISK ASSOCIATED THEREWITH. ALL INFORMATION, PRODUCTS OR SERVICES
							CONTAINED ON OR PROVIDED THROUGH THIS WEBSITE ARE PROVIDED "AS IS" WITHOUT ANY WARRANTY OF ANY
							KIND, EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW ELEVATEU LLC
							UNIVERSITY AND ITS AFFILIATES, DIRECTORS, OFFICERS, MANAGERS, EMPLOYEES OR OTHER REPRESENTATIVES
							(COLLECTIVELY, "AFFILIATES") HEREBY DISCLAIM ALL REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED,
							STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
							PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT AND FREEDOM FROM COMPUTER VIRUS STRAINS. WITHOUT LIMITING
							THE FOREGOING, ELEVATEU AND ITS AFFILIATES MAKE NO WARRANTY AS TO THE RELIABILITY, ACCURACY, TIMELINESS,
							USEFULNESS, ADEQUACY, COMPLETENESS OR SUITABILITY OF THE MOSHEMU WEBSITE, INFORMATION RECEIVED THROUGH
									THE MOSHEMU WEBSITE, AND OTHER SERVICES PROVIDED HEREUNDER.</li>
						</ul>
						<li>Limitation of Liability</li>
						<ul>
							<li>IN NO EVENT SHALL MOSHEMU OR ELEVATEU BE LIABLE FOR ANY DAMAGES OR OTHER LIABILITY TO YOU OR ANY
							OTHER USERS OF THE ELEVATEU WEBSITE.  TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL
							MOSHEMU OR ELEVATEU OR ANY OF ITS AFFILIATES BE LIABLE FOR ANY SPECIAL, PUNITIVE, INDIRECT,
							INCIDENTAL OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO PERSONAL INJURY, WRONGFUL
							DEATH, LOSS OF GOODWILL, LOSS OF USE, LOSS OF PROFITS, INTERRUPTION OF SERVICE OR LOSS OF DATA,
							WHETHER IN ANY ACTION IN WARRANTY, CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO NEGLIGENCE OR
							FUNDAMENTAL BREACH), OR OTHERWISE ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF, OR THE
							INABILITY TO USE, THIS WEBSITE OR ANY SERVICE OFFERED THROUGH THIS WEBSITE OR ANY MATERIAL OR
							INFORMATION CONTAINED IN, ACCESSED THROUGH, OR PRODUCTS OR SERVICES OBTAINED THROUGH THIS
							WEBSITE, EVEN IF AN AUTHORIZED REPRESENTATIVE OF MOSHEMU IS ADVISED OF THE LIKELIHOOD OR
							POSSIBILITY OF THE SAME. TO THE EXTENT ANY OF THE ABOVE LIMITATIONS OF LIABILITY ARE RESTRICTED
							BY APPLICABLE FEDERAL, STATE OR LOCAL LAW, SUCH LIMITATIONS SHALL NOT APPLY TO THE EXTENT OF
									SUCH RESTRICTIONS.</li>
						</ul>
					</ul>
					<h3>Section 4. General Provisions</h3>
					<ul>
						<li>Indemnity</li>
						<ul>
							<li>YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS MOSHEMU, ELEVATEU AND ITS EMPLOYEES, AND
							AGENTS FROM AND AGAINST ALL LOSSES, LIABILITY, EXPENSES, DAMAGES AND COSTS, INCLUDING REASONABLE
							ATTORNEY'S FEES, ARISING OUT OF OR RELATED TO ANY BREACH OF THIS TERMS OF USE, ANY NEGLIGENT
							OR WRONGFUL ACTION OR OMISSION BY YOU RELATED TO YOUR USE OF OR PROVIDING OF SERVICES THROUGH
							THE MOSHEMU WEBSITE, OR ANY NEGLIGENT OR WRONGFUL USE OF THE MOSHEMU WEBSITE (INCLUDING,
							WITHOUT LIMITATION, INFRINGEMENT OF THIRD PARTY INTELLECTUAL PROPERTY RIGHTS OR NEGLIGENT OR
									WRONGFUL CONDUCT) BY YOU OR ANY OTHER PERSON ACCESSING YOUR ACCOUNT.</li>
						</ul>
						<li>Third Party Rights</li>
						<ul>
							<li>The indemnification provisions set forth above and the provisions under Section 3 Disclaimers
							are for the benefit of Vanderbilt University and its affiliates. Each of these individuals or
							entities shall have the right to assert and enforce those provisions directly against you on
									its own behalf.</li>
						</ul>
						<li>Term and Termination</li>
						<ul>
							<li>This Terms of Use shall commence upon the date you first access or use Moshemu website.
							Either you Moshemu may terminate this Terms of Use and your right to use the Moshemu website
							at any time, with or without cause. This Terms of Use and the licenses granted hereunder
							shall terminate without notice in the event you (or any authorized person using your account)
							fail to comply with the terms and conditions of this Terms of Use or the Moshemu registry
									posted on the Moshemu website from time to time.</li>
						</ul>
						<li>Notice</li>
						<ul>
							<li>Moshemu may provide notice by e-mail to the e-mail address you provided Moshemu by a general
							notice on the Moshemu website, or by written communication delivered by first class U. S.
							mail or express courier to your address on record in the Moshemu account information. You
									may give notice to Moshemu at any time via electronic mail to “ElevateULLC@gmail.com”, </li>
							<li>This Terms of Use shall be governed by and construed in accordance with the laws
							of the State of California without giving effect to any choice of law rules or
							principles. You agree that Orange County, California shall be the sole and
							exclusive venue for any legal action or proceeding between Moshemu or ElevateU
							and you for any purpose concerning this Terms of Use. Any cause of action or claim
							you may have with respect to the use of the Moshemu website must be commenced within
							one (1) year after it arises, except to the extent such limitation is not enforceable.
							To the fullest extent permitted by law, each party to this Terms of Use waives its or
							his or her right to a jury trial with respect to any dispute or other controversy arising
									from hereunder or your use of or access to the Moshemu web site.</li>
						</ul>
						<li>Severability</li>
						<ul>
							<li>The provisions of this Terms of Use are severable, and in the event any provision
							hereof is determined to be invalid or unenforceable, such invalidity or
							unenforceability shall not affect the validity or enforceability of the remaining
							provisions, but such provision shall be reformed, if reasonably possible, only to
									the extent necessary to make it enforceable.</li>
						</ul>
						<li>Waiver</li>
						<ul>
							<li>If Moshemu does not exercise or enforce any particular right or provision in these
							Terms, Moshemu does not waive that right or provision. Failure to exercise or delay
							in exercising any right hereunder, or failure to insist upon or enforce strict
							performance of any provision of this Terms of Use, shall not be considered waiver
							thereof, which can only be made by a signed writing. No single waiver shall be
									considered a continuing or permanent waiver.</li>
						</ul>
						<li>Assignment</li>
						<ul>
							<li>You may not assign, transfer, or delegate this Terms of Use or any part of it without
							the prior written consent of Moshemu. Moshemu may freely transfer, assign or delegate
							all or any part of this Terms of Use and any rights and duties hereunder. This Terms of
							Use will be binding upon and inure to the benefit of the heirs, successors and permitted
									assigns of the parties.</li>
						</ul>
						<li>Change to Terms of Use</li>
						<ul>
							<li>Moshemu reserves the right to update or change these Terms of Use at any time and
							for any reason by posting the modified Terms of Use. Your continued use constitutes
							your agreement to be bound by any such revisions and you should therefore periodically
							visit this page of the website and print the latest version of the Terms of Use for your
							records. The date of the last update to these Terms of Use is stated at the top of this
									document. All Terms are automatically effective as soon as they are posted.</li>
						</ul>
						<li>Limitation of Services</li>
						<ul>
							<li>Not all studies posted on Moshemu.com provide free counseling or other services to participants</li>
						</ul>
					</ul>
					<li>
						Research reported in this publication was supported by the National Institute On Drug abuse of
						the National Institutes of Health under Award Number R43DA048670. The content is solely the
						responsibility of the authors and does not necessarily represent the official views of the National
						Institutes of Health.
					</li>
					<br/>
					<li>Thank you for reading the Moshemu Terms of Use.</li>
				</div>
			</div>
		);
export default TermsAndConditions;