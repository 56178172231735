import { h, Component, createContext } from 'preact';
import UserInterface from './userInterface';
import { useHistory } from 'react-router-dom';

// export const API_URL_PREFIX = 'http://34.214.236.87:8000';
export const API_URL_PREFIX = 'https://api.moshemu.com';

// No initial value provided on purpose - the exported Provider component will supply it
export const AuthContext = createContext({});

interface UserAuth {
    userId: string;
    accessToken: string;
    refreshToken: string;
};

interface LocalStorageModel {
    user: UserAuth,
    userInfo: UserInterface,
}

interface UserPayload {
    token_type: string;
    exp: number;
    jti: string;
    user_id: string;
}

export type TokenTypes = "refresh" | "access";
interface Props {

}

export interface AuthContextState {
    userAuth: Partial<UserAuth>;
    userInfo: Partial<UserInterface>;
    getNewToken: (username: string, password: string) => Promise<boolean>;
    refreshExistingToken: () => Promise<void>;
    logout: () => void;
    getUserInfo: () => Promise<UserInterface>;
    tokenHasNotExpired: (type: TokenTypes) => boolean;
    hasValidAuth: () => Promise<boolean>;
}


export class AuthContextProvider extends Component<Props, AuthContextState> {
    state: AuthContextState;
    constructor(props) {
        super(props);
        this.state = {
            userAuth: {
                userId: null,
                accessToken: null,
                refreshToken: null,
            },
            userInfo: {},
            getNewToken: this.getNewToken,
            refreshExistingToken: this.refreshExistingToken,
            logout: this.logout,
            getUserInfo: this.getUserInfo,
            tokenHasNotExpired: this.tokenHasNotExpired,
            hasValidAuth: this.hasValidAuth,
        }
    }

    /**
     * Given a pair of string email address and password, obtains the access and refresh 
     * tokens from the server using a POST request. This function will also extract the
     * string representation of the userId by decoding the tokens.
     * 
     * Returns a promise that resolves to an object that contains the access token,
     * refresh token and user ID (UUID).
     * 
     * @param {string} userName The account's email address for obtaining tokens
     * @param {string} password The account's password for obtaining tokens
     * 
     * @returns {Promise<{
     * accessToken: string,
     * refreshToken: string,
     * userId: string
     * }>} Promise resolving to an object that contains the access token, refresh token
     * and user ID (UUID).
     */
    private obtainNewTokens = async (userName: string, password: string): Promise<{
        accessToken: string;
        refreshToken: string;
        userId: string;
    }> => {
        if (!userName || !password) {
            throw 'Invalid username or password';
        }

        // Construct the body of the POST request needed to retrieve access and refresh tokens
        const payload: object = {
            username: userName,
            password: password,
        };

        return await fetch(`${API_URL_PREFIX}/auth/obtain_token/`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify(payload)
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw response.status;
                }
            })
            .then(jsonResponse => {
                const rawAccessToken: string = jsonResponse['access'];
                const encodedpieces: string[] = rawAccessToken.split(".");
                const signature = encodedpieces.pop();
                let header: object;
                let payload: UserPayload
                [header, payload] = encodedpieces.map(item => JSON.parse(window.atob(item)));

                return {
                    accessToken: rawAccessToken,
                    refreshToken: jsonResponse['refresh'],
                    userId: payload.user_id,
                }
            });
    }

    /**
     * Given a pair of string email address and password, retrieves the access and refresh
     * tokens and asynchronously updates this context's state with the server response.
     * Also sets the window's localStorage to store the tokens.
     * @param {string} username The email address for the account
     * @param {string} password The password for the account
     */
    getNewToken = async (username: string, password: string) => {
        let newTokens;
        await this.obtainNewTokens(username, password).then(tokens => {
            newTokens = tokens;
        }, errors => {
            newTokens = false;
        });
        if (!newTokens) return false;
        const newUserAuth: UserAuth = {
            ...this.state.userAuth,
            ...newTokens,
        } as UserAuth;
        await this.updateUser(newUserAuth);
        return true;
    }

    /**
     * Using the refresh token stored in this context, attempts to retrieve a new access
     * token and store it in this context as well as the localStorage.
     * 
     * This function should be called when any authentication-required requests returns a 
     * response indication token expiry.
     */
    refreshExistingToken = async (): Promise<void> => {
        if (!this.state.userAuth.refreshToken) {
            throw 'No existing refresh token found';
        }

        const payload: object = {
            refresh: this.state.userAuth.refreshToken,
        };

        await fetch(`${API_URL_PREFIX}/auth/refresh_token/`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(jsonResponse => {
                const newUserAuth: object = {
                    ...this.state.userAuth,
                    accessToken: jsonResponse['access']
                } as UserAuth;

                window.localStorage.setItem('userAuth', JSON.stringify(newUserAuth));
                this.setState({
                    userAuth: {
                        ...this.state.userAuth,
                        accessToken: jsonResponse['access']
                    }
                });
            });
            window.location.reload();
    }

    /**
     * Verifies whether the refresh token stored in this context has expired by decoding it and
     * comparing its expiry time to the current time.
     * 
     * @returns {boolean} True if the refresh token is still valid. False when it was expired.
     */
    tokenHasNotExpired = (type: TokenTypes): boolean => {
        const currentToken: string = type === "access" ? this.state.userAuth.accessToken : this.state.userAuth.refreshToken
        if (!currentToken) {
            throw `No ${type} token is currently stored in the context`;
        }

        const [rawHeader, rawPayload, signature] = currentToken.split('.');
        const decodedPayload: object = JSON.parse(atob(rawPayload));

        return Date.now() < decodedPayload['exp'] * 1000;
    }
    /**
     * This function checks whether the user has valid authentication information by checking the
     * stored access and refresh tokens. If the access token is expired, the function will attempt to
     * renew it using the refresn token. In case the refresh token is also expired, the function will
     * log the user out.
     * 
     * @returns {Promise<boolean>} Whether the user has valid authentication or not
     */
    hasValidAuth = async (): Promise<boolean> => {
        if (this.tokenHasNotExpired("access")) {
            return true;
        } else if (this.tokenHasNotExpired("refresh")) {
            await this.refreshExistingToken();
            this.hasValidAuth();
            return false;
        } else {
            this.logout()
            return false;
        }

    }
    /**
     * This helper function is called after the user logs in and the context receives the
     * access and refresh tokens. It uses the UUID decoded from the tokens as well as the
     * access token to retrieve the full user information from the server.
     * 
     * @returns {Promise<{
     * userId: string,
     * userInfo: UserInterface
     * }>} A promise that resolves to an object containing the UUID and the full
     * user information
     */
    private obtainUserInfo = async (): Promise<{ userId: string; userInfo: UserInterface; }> => {
        const userId: string = this.state.userAuth.userId;
        if (!userId) {
            throw 'No existing user ID found';
        }

        const headers = new Headers({
            'Authorization': `Bearer ${this.state.userAuth.accessToken}`
        })

        return await fetch(`${API_URL_PREFIX}/user/${userId}/`, {
            method: 'GET',
            headers: headers,
        })
            .then(response => {
                if (response.status === 401) {
                    throw {
                        code: 401,
                        response: response.json(),
                    }
                }
                return response.json();
            })
            .then((jsonResponse: UserInterface) => ({
                userId: jsonResponse.id,
                userInfo: jsonResponse,
            }))
            .catch(error => {
                if (error.code && error.code === 401) {
                    // Supposedly both EXPIRED and INVALID tokens return the exact same thing
                    // TODO: figure out what the heck should happen
                }

                return {
                    userId: null,
                    userInfo: null,
                }
                // TODO: ^^^^ fix that!
            })
    }

    /**
     * Using the user ID and access token stored in this context, asynchronously retrieves
     * the full user information for the current user and stores all fields in this context
     * and to the localStorage.
     */
    getUserInfo = async (): Promise<UserInterface> => {
        const response = await this.obtainUserInfo();
        const newUserInfo = response.userInfo;
        await this.updateUserInfo(newUserInfo);
        return newUserInfo;
    }

    /**
     * Erases all tokens and user information from this context, in addition to clearing the
     * related fields in localStorage.
     */
    logout = (): void => {
        window.localStorage.removeItem('userAuth');
        window.localStorage.removeItem('userInfo');
        this.setState({
            userAuth: {
                userId: null,
                accessToken: null,
                refreshToken: null,
            },
            userInfo: null,
        }, () => useHistory().push('/'));
    }

    componentDidMount() {
        const { userAuth, userInfo } = this.readFromLocalStorage();
        if (userAuth && userInfo) {
            this.setState({
                userAuth,
                userInfo,
            }, () => this.hasValidAuth());
        } else {
            this.setState({
                userAuth,
                userInfo,
            });
        }
    }

    /**
     * Read two fields from localStorage and returns an object that contains the tokens and the
     * full user information fields.
     * 
     * @returns {{
     * userAuth: UserAuth,
     * userInfo: UserInterface
     * }} An object that contains two objects - one storing the tokens and one storing the
     * full user information fields.
     */
    readFromLocalStorage = (): { userAuth: UserAuth; userInfo: UserInterface; } => {
        const userAuth: string = window.localStorage.getItem('userAuth');
        const userInfo: string = window.localStorage.getItem('userInfo');

        return {
            userAuth: userAuth ? JSON.parse(userAuth) : userAuth,
            userInfo: userInfo ? JSON.parse(userInfo) : userInfo,
        }
    }

    /**
     * Takes a UserAuth object, saves its information to localStorage, then updates the 
     * context's state with the new tokens.
     * 
     * @param {UserAuth} userAuth 
     */
    updateUser = async (userAuth: UserAuth): Promise<void> => {
        window.localStorage.setItem('userAuth', JSON.stringify(userAuth));
        await new Promise(resolve => this.setState({ userAuth: userAuth }, resolve));
    }

    /**
     * Takes a UserInterface object, saves its information to localStorage, then updates the 
     * context's state with the new information fields.
     * 
     * @param {UserInterface} userInfo 
     */
    updateUserInfo = async (userInfo: UserInterface): Promise<void> => {
        window.localStorage.setItem('userInfo', JSON.stringify(userInfo));
        await new Promise(resolve => this.setState({ userInfo: userInfo }, resolve));
    }

    public render(props, state: AuthContextState) {
        return (
            <AuthContext.Provider value={state}>
                {props.children}
            </AuthContext.Provider>
        )
    }
}

export const AuthContextConsumer = Component => {
    return props => {
        return (
            <AuthContext.Consumer>
                {globalAuthContext => <Component {...globalAuthContext} {...props} />}
            </AuthContext.Consumer>
        )
    }
}