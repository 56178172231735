import { h } from 'preact';
import * as style from './style.scss';

const FAQ = () => (
    <div class={style.faq}>
        <div class={`wrapper ${style.contentContainer}`}>
            <h2 class={style.longTitle}>Frequently Asked Questions</h2>
            <h2 class={style.shortTitle}>FAQ</h2>
            <section>
                <h3>What is Moshemu?</h3>
                <p>
                    Moshemu is an online research study recruitment platform. Moshemu was funded
                    by the National Institute on Drug Abuse in 2019 and was created by a team of
                    researchers, doctors, and social workers who care deeply about helping those
                    who use substances.
                </p>
            </section>
            <section>
                <h3>Who Runs Moshemu?</h3>
                <p>
                    The Mosehmu staff work with research scientists at major universities like UCLA,
                    UC Irvine, Brown University, and Columbia University to find interested participants
                    to join research studies.
                </p>
            </section>
            <section>
                <h3>Why was Moshemu Created?</h3>
                <p>Conducting research studies and finding the right participants can take a lot of time
                    and money, and thus Moshemu was created to speed up that matching process. <br /><br />

                    One way to help substance users live better lives is to conduct research on ways to help
                    them improve their health and mental health, this only happens through research.
                </p>
            </section>
            <section>
                <h3>What can I get out of joining Moshemu?</h3>
                <p>
                    For people who join studies AKA “Participants,” joining a study can be a great way to <b>make money
                    and receive free services</b> like healthcare, counseling, and medications.*
                    <br/>
                    <br/>
                    * not all studies offer money or free services for joining
                </p>
            </section>
            <section>
                <h3>What will you do with information Moshemu collects from me?</h3>
                <p>
                    We will use this information to help match you up with potential studies you can join and be paid to
                    participate in. 
                </p>
                <p>
                    <em>We will NEVER sell your information or give your information to any third parties or
                    to law enforcement.</em> In fact, the National Institute of Health studies on Moshemu have been approved
                    by academic ethical review boards (IRB’s). These studies have a “Certificate of Confidentiality,”
                    meaning that the federal government has assured that we do not need to provide data to anyone ever,
                    and can keep it secure and confidential.
                </p>
            </section>
        </div>
    </div>
)

export default FAQ;