import { h, Component } from 'preact';
import * as style from './style.scss';
import CustomForm from '../../components/customForm';
import CustomInput, { TelephoneInput, PasswordInput } from '../../components/customInput'
import { withRouter, RouteComponentProps } from 'react-router';
import { formatPhoneNumber } from '../../util/phoneNumber';
import { API_URL_PREFIX } from '../../util/authContext'
import { toast } from 'react-toastify';


interface Props extends RouteComponentProps { }
interface State {
	telephone: string;
	telephoneError: string;

	code: string;
	codeError: string;

	password: string
	passwordError: string;

	onCodeInput: boolean;
	onResetPage: boolean;

}

class OriginalResetPassword extends Component<Props, State> {
	state: State = {
		telephone: '',
		telephoneError: undefined,

		code: '',
		codeError: undefined,

		password: '',
		passwordError: undefined,

		onCodeInput: false,
		onResetPage: false,
	}

	/**
	 * Takes an array of errors, and concatenates them into 1 error message
	 * @param {string[]} errArray The array of errors
	 * 
	 * @return {string} Returns a string in the form of - error message, error message 2, error message 3
	 */
	concatenateErrors = (errArray: string[]): string => errArray ? errArray.join(', ') : undefined;
	
	// Decrement the current page that the user is on, or send them back to the login page if they decide not
	// to follow through with the reset
	decrementPage = () => {
		if (this.state.onResetPage) {
			this.setState({ onResetPage: !this.state.onResetPage });
		} else if (this.state.onCodeInput) {
			this.setState({ onCodeInput: !this.state.onCodeInput });
		} else {
			this.props.history.push('/login');
		}
	}

	// Increment the current page that the user is on
	incrementPage = () => {
		if (!this.state.onCodeInput) {
			this.setState({ onCodeInput: !this.state.onCodeInput });
		} else if (!this.state.onResetPage) {
			this.setState({ onResetPage: !this.state.onResetPage });
		}
	}

	/**
	 * Uses the user's phone number and checks whether the number matches to an existing account.
	 * If so, initiates a request for the user to receive an SMS verification code,
	 * If not, displays an error message
	 */
	handlePageOne = async ({ tel }) => {
		const payload: object = {
			email: '',
			code: '',
			mobile: formatPhoneNumber(tel),
			password: '',
			first_name: '',
			last_name: '',
		};			

		await fetch(`${API_URL_PREFIX}/user/`, {
			method: 'POST',
			headers: new Headers({ 'Content-Type': 'application/json' }),
			body: JSON.stringify(payload),
		})
			.then(async response => {
				if (response.status === 400) {
					const errors = await response.json();
					if (errors['mobile'] && errors['mobile'][0] === 'User exists') {
						// User exists, proceed to next page
						const mobilePayload: object = {
							mobile: formatPhoneNumber(tel),
						};
						fetch(`${API_URL_PREFIX}/sms_verification_code/`, {
							method: 'POST',
							headers: new Headers({ 'Content-Type': 'application/json' }),
							body: JSON.stringify(mobilePayload),
						})
							.then(response => response.json())
							.then(() => {
								toast(`You should receive a verification SMS at ${tel} soon`);
								this.setState({
									telephone: formatPhoneNumber(tel),
								});
							})
						this.incrementPage();
					} else {
						// There is no user, trigger server error message
						this.setState({
							telephoneError: "There are no accounts registered with that phone number"
						})
					}
				}
			}, error => {
				error;
			});
	}

	/**
	 * Checks whether the verification code is valid. If so, advance to the next page that accepts
	 * a new password. If not, displays an error message.
	 */
	handlePageTwo = async ({ code }) => {
		if (code) {
			const payload = {
				code: code,
				mobile: this.state.telephone,
				password: null,
			};

			await fetch(`${API_URL_PREFIX}/user_password_reset/`, {
				method: 'POST',
				headers: new Headers({
					'Content-Type': 'application/json',
				}),
				body: JSON.stringify(payload),
			})
			.then(async response => {
				if (response.status === 400) {
					const errors = await response.json();
					if (errors['code']){
						//This is a failed code
						this.setState({
							codeError: this.concatenateErrors(errors['code']),
						});
					} else {
						this.setState({
							code: code,
						});
						this.incrementPage();
					}
				}
			});
		}
	}

	/**
	 * Attempts to reset the password using the phone number, verification code and the new password.
	 * If success, toast and redirect to the login page. If not, display an error message. 
	 */
	handlePageThree = async ({ createPassword }) => {
		if (createPassword) {
			const payload = {
				code: this.state.code,
				mobile: this.state.telephone,
				password: createPassword,
			};
		
			await fetch(`${API_URL_PREFIX}/user_password_reset/`, {
				method: 'POST',
				headers: new Headers({
					'Content-Type': 'application/json',
				}),
				body: JSON.stringify(payload),
			})
			.then(async response => {
				if (response.ok && response.status === 200) {
					toast('You have successfully reset your password!');
					this.props.history.push('/login');
				} else {
					if (response.status === 400) {
						const errors = await response.json();
						if (errors['password']){
							//This is a failed password
							this.setState({
								passwordError: this.concatenateErrors(errors['password']),
							});
						}
					}
				}
			});
		}
	}

	/**
	 * Takes an event emitted by the password input field and stores the current password in this component's state
	 * This is used to dynamically set the pattern for "Confirm Password" so that error messages can be displayed
	 * even at runtime
	 * 
	 * @param {Event} event The event emitted by te password input field
	 */
	handlePasswordInput = (event: Event) => {
		if (!event) return;
		const passwordInput: HTMLInputElement = event.target as HTMLInputElement;
		if (passwordInput) {
			this.setState({ password: passwordInput.value });
		}
	}

	passwordOnBlur = () => {
		this.setState({ passwordError: undefined });
	}

	phoneOnBlur = () => {
		this.setState({ telephoneError: undefined })
	}

	codeOnBlur = () => {
		this.setState({ codeError: undefined })
	}

	public render(_: Props, { passwordError, telephoneError, onCodeInput, onResetPage, codeError }: State) {
		return (
			<div class={style.resetPassword}>
				<div class={style.box}>
					<h1>ResetPassword</h1>
					<div class={style.displayWindow + (onResetPage ? ` ${style.pageThree}` : onCodeInput ? ` ${style.pageTwo}` : ` ${style.pageOne}`)}>
						<CustomForm
							submitText="Next"
							cancelText="Cancel"
							cancelCallback={this.decrementPage}
							submitCallback={this.handlePageOne}
							class={style.firstPage}>
							<TelephoneInput
								handleBlur={this.phoneOnBlur}
								errors={{ serverError: telephoneError }} />
						</CustomForm>
						<CustomForm
							submitText="Next"
							cancelText="Back"
							cancelCallback={this.decrementPage}
							submitCallback={this.handlePageTwo}
							class={style.secondPage}>
							<CustomInput
								type="number"
								label="SMS Verification Code"
								name="code"
								pattern="[0-9]{6}"
								handleBlur={this.codeOnBlur}
								placeholder="Enter your 6-digit Verification Code"
								errors={{
									patternMismatch: 'Please enter a valid verification code',
									serverError: codeError
								}} />

						</CustomForm>
						<CustomForm
							submitText="Submit"
							cancelText="Back"
							cancelCallback={this.decrementPage}
							submitCallback={this.handlePageThree}
							class={style.thirdPage}>
							<PasswordInput
								name="createPassword"
								label="Create Password"
								pattern="(?=.*\d)(?=.*[a-zA-Z]).{8,}"
								errors={{
									patternMismatch: 'Your password should contain at least 8 characters with numbers and letters',
									serverError: passwordError
								}}
								handleInput={this.handlePasswordInput}
								handleBlur={this.passwordOnBlur} />
							<PasswordInput
								name="confirmPassword"
								label="Confirm Password"
								pattern={this.state.password}
								errors={{ patternMismatch: 'The two passwords must be the same' }} />
						</CustomForm>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter<Partial<Props> & RouteComponentProps, any>(props => <OriginalResetPassword  {...props} /> as any);