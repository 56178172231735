import { h, Component } from 'preact';
import * as style from './style.scss';

interface Props {
    size?: number;
    color?: string;
}

export default class LoadingSpinner extends Component<Props> {
    public render({ size = 36, color = '#ffffff' }: Props) {
        return (
            <svg class={style.loadingSpinner} width={`${size}px`} height={`${size}px`}>
                <circle cx={String(size / 2)} cy={String(size / 2)} r={String(size / 3.6)}
                    fill="none" stroke={color} stroke-width={String(size / 9)} 
                    stroke-dasharray={String(size * 1.25)}>
                </circle>
            </svg>
        )
    }
}